import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  "en": {
    "translation": {
      "image": "Image",
      "fullName": "Full Name",
      "email": "Email",
      "active": "Active",
      "branch": "Branch",
      "drivers": "Drivers",
      "error": "Error",
      "failedToFetchData": "Failed to fetch data.",
      "file_too_large": "The file size exceeds 1MB",
      "noRecordsFound": "No records found.",
      "monthlyPickupStatistics": "Monthly Pickup Statistics",
      "pickedCars": "Picked Cars",
      "droppedCars": "Dropped Cars",
      "cars": "Cars",
      "errorFetchingCars": "Error fetching car data.",
      "month": "Month",
      "pickups": "Pickups",
      "dashboard": "Dashboard",
      "picked": "Picked",
      "dropped": "Dropped",
      "drivers": "Drivers",
      "branches": "Branches",
      "view": "View",
      "edit": "Edit",
      "dropIt": "Drop it",
      "carBrandLogo": "Car Logo",
      "carBrand": "Car Brand",
      "licensePlate": "License Plate",
      "driver": "Driver",
      "branch": "Branch",
      "pickupTime": "Pickup Time",
      "dropOffTime": "Drop Time",
      "customerMobile": "Customer Mobile",
      "close": "Close",
      "carDetails": "Car Details",
      "editCarDetails": "Edit Car Details",
      "update": "Update",
      "cancel": "Cancel",
      "updated": "Updated!",
      "carDetailsUpdated": "Car details have been updated.",
      "error": "Error!",
      "carUpdateError": "There was an issue updating the car.",
      "markCarDropped": "Mark Car as Dropped?",
      "doYouWantToMark": "Do you want to mark the car with",
      "asDropped": "as dropped",
      "yesDropIt": "Yes, Drop it",
      "dropped": "Dropped!",
      "carMarkedDropped": "The car has been marked as dropped.",
      "carDropError": "There was an issue marking the car as dropped.",
      "view": "View",
      "edit": "Edit",
      "dropIt": "Drop it",
      "action": "Action",
      "markCarAsDropped": "Mark Car as Dropped?",
      "doYouWantToMark": "Do you want to mark the car with License Plate:",
      "asDropped": "as dropped?",
      "yesDropIt": "Yes, Drop it",
      "cancel": "Cancel",
      "dropped": "Dropped",
      "carMarkedDropped": "The car has been marked as dropped.",
      "error": "Error!",
      "carDropError": "There was an issue marking the car as dropped.",
      "addNewCar": "Add New Car",
      "carBrand": "Car Brand",
      "licensePlate": "License Plate",
      "serialNumber": "Serial Number",
      "customerMobile": "Customer Mobile",
      "pickUp": "Pick Up",
      "pleaseFillFields": "Please fill in all required fields.",
      "added": "Added!",
      "newCarAdded": "New car has been added.",
      "addCarError": "There was an issue adding the car.",
      "allBranches": "All Branches",
      "all": "All",
      "last1Week": "Last 1 Week",
      "last1Month": "Last 1 Month",
      "last3Months": "Last 3 Months",
      "last6Months": "Last 6 Months",
      "last1Year": "Last 1 Year",
      "customDatesRange": "CUSTOM DATES Range",
      "exportToPDF": "Export to PDF",
      "pickedUpCars": "Picked up Cars",
      "addCar": "+ Add a Car",
      "areYouSure": "Are you sure?",
      "deleteCarText": "Do you want to delete the car with License Plate:",
      "yesDelete": "Yes, Delete",
      "cancel": "Cancel",
      "deleted": "Deleted!",
      "carDeleted": "The car has been deleted.",
      "errorTitle": "Error",
      "fetchError": "Failed to fetch data.",
      "deleteError": "There was an issue deleting the car.",
      "delete": "Delete",
      "dropedCars": "Droped Cars",
      "drivers": "Drivers",
      "drivers_list": "Drivers List",
      "edit_driver": "Edit Driver",
      "add_new_driver": "Add New Driver",
      "full_name": "Full Name",
      "email": "Email",
      "active": "Active",
      "branch": "Branch",
      "profile_pic": "Profile Picture",
      "phone_number": "Phone Number",
      "mobile_number": "Mobile Number",
      "password": "Password",
      "choose_branch": "Choose Branch",
      "add_driver": "Add Driver",
      "save_changes": "Save Changes",
      "cancel": "Cancel",
      "delete": "Delete",
      "are_you_sure": "Are you sure you want to delete",
      "error": "Error",
      "error_adding_driver": "Error adding driver",
      "error_updating_driver": "Error updating driver",
      "driver_added": "Driver successfully added",
      "driver_updated": "Driver successfully updated",
      "driver_deleted": "Driver successfully deleted",
      "driver_not_deleted": "Driver not deleted. Please try again.",
      "all_fields_required": "All fields are required",
      "failed_fetch_data": "Failed to fetch data",
      "added": "Added",
      "updated": "Updated",
      "deleted": "Deleted",
      "driver_details": "Driver Details",
      "add_new_branch": "Add New Branch",
      "logo": "Logo",
      "name": "Name",
      "address": "Address",
      "active": "Active",
      "cancel": "Cancel",
      "add_branch": "Add Branch",
      "failed_to_load_branches": "Failed to load branches.",
      "save_changes": "Save Changes",
      "edit_branch": "Edit Branch",
      "delete": "Delete",
      "are_you_sure": "Are you sure you want to delete",
      "yes": "Yes",
      "no": "No",
      "close": "Close",
      "view": "View",
      "edit": "Edit",
      "deleted": "Deleted",
      "has_been_deleted": "has been deleted",
      "updated": "Updated",
      "has_been_updated": "has been updated",
      "added": "Added",
      "has_been_added": "has been added to the list",
      "error": "Error",
      "request_failed": "Request failed",
      "failed_to_delete": "Failed to delete",
      "logo": "Logo",
      "name": "Name",
      "active": "Active",
      "address": "Address",
      "branches": "Branches",
      "+_add_branch": "+ Add Branch",
      "drivers": "Drivers",
      "+_add_driver": "+ Add Driver",
      "profile": "Profile",
      "my_profile": "My Profile",
      "logout": "Logout",
      "admin_name": "Admin Name",
      "change_password": "Change Password",
      "all_rights_reserved": "All Rights Reserved",
      "picked_up_cars": "Picked up Cars",
      "+_add_car": "+ Add a Car",
      "change_banner": "Change Banner",
      "choose_banner": "Choose Banner",
      "update_banner": "Update Banner",
      "cancel": "Cancel",
      "select_banner": "Please select a banner image!",
      "success": "Success!",
      "banner_updated": "Banner updated successfully.",
      "error": "Error!",
      "failed_update_banner": "Failed to update banner.",
      "login_here": "LOGIN HERE",
      "enter_username": "Enter username",
      "enter_password": "Enter password",
      "remember_me": "Remember me",
      "forgot_password": "Forgot Password?",
      "login": "LOGIN",
      "please_provide_valid_input": "Please provide valid input",
      "forgot_password": "Forgot Password",
      "enter_email": "Enter your email",
      "send_otp": "Send OTP",
      "enter_otp": "Enter OTP",
      "verify_otp": "Verify OTP",
      "reset_password": "Reset Password",
      "new_password": "New Password",
      "confirm_password": "Confirm Password",
      "please_provide_valid_email": "Please provide a valid email",
      "please_provide_valid_otp": "Please provide a valid OTP",
      "password_reset_successful": "Password reset successful",
      "passwords_do_not_match": "Passwords do not match"
    }
  },
  "fr": {
    "translation": {
      "image": "Image",
      "fullName": "Nom complet",
      "email": "Email",
      "active": "Actif",
      "branch": "Succursale",
      "drivers": "Chauffeurs",
      "error": "Erreur",
      "failedToFetchData": "Impossible de récupérer les données.",
      "noRecordsFound": "Aucun enregistrement trouvé.",
      "monthlyPickupStatistics": "Statistiques des ramassages mensuels",
      "pickedCars": "Voitures ramassées",
      "droppedCars": "Voitures déposées",
      "cars": "Voitures",
      "errorFetchingCars": "Erreur lors de la récupération des données des voitures.",
      "month": "Mois",
      "pickups": "Ramassages",
      "dashboard": "Tableau de bord",
      "picked": "Ramassées",
      "dropped": "Déposées",
      "drivers": "Chauffeurs",
      "branches": "Succursales",
      "view": "Voir",
      "edit": "Éditer",
      "dropIt": "Déposer",
      "carBrandLogo": "Logo de la voiture",
      "licensePlate": "Plaque d'immatriculation",
      "customerMobile": "Numéro du client",
      "carBrand": "Marque de voiture",
      "licensePlate": "Plaque d'immatriculation",
      "driver": "Chauffeur",
      "branch": "Succursale",
      "pickupTime": "Heure de collecte",
      "dropOffTime": "Heure de dépose",
      "close": "Fermer",
      "carDetails": "Détails de la voiture",
      "editCarDetails": "Modifier les détails de la voiture",
      "update": "Mettre à jour",
      "cancel": "Annuler",
      "updated": "Mis à jour !",
      "carDetailsUpdated": "Les détails de la voiture ont été mis à jour.",
      "error": "Erreur !",
      "carUpdateError": "Il y a eu un problème lors de la mise à jour de la voiture.",
      "markCarDropped": "Marquer la voiture comme déposée ?",
      "markCarAsDropped": "Marquer la voiture comme déposée ?",
      "doYouWantToMark": "Voulez-vous marquer la voiture avec",
      "asDropped": "comme déposée ?",
      "yesDropIt": "Oui, déposez-la",
      "dropped": "Déposée !",
      "carMarkedDropped": "La voiture a été marquée comme déposée.",
      "carDropError": "Il y a eu un problème lors de la marque de la voiture comme déposée.",
      "view": "Voir",
      "edit": "Éditer",
      "dropIt": "Déposer",
      "action": "Action",
      "allBranches": "Toutes les succursales",
      "all": "Tous",
      "last1Week": "Dernière semaine",
      "last1Month": "Dernier mois",
      "last3Months": "Derniers 3 mois",
      "last6Months": "Derniers 6 mois",
      "last1Year": "Dernière année",
      "customDatesRange": "Plage de dates personnalisées",
      "exportToPDF": "Exporter en PDF",
      "pickedUpCars": "Voitures ramassées",
      "addCar": "+ Ajouter une voiture",
      "areYouSure": "Êtes-vous sûr ?",
      "deleteCarText": "Voulez-vous supprimer la voiture avec la plaque d'immatriculation:",
      "yesDelete": "Oui, supprimer",
      "cancel": "Annuler",
      "deleted": "Supprimé !",
      "carDeleted": "La voiture a été supprimée.",
      "errorTitle": "Erreur",
      "fetchError": "Échec de la récupération des données.",
      "deleteError": "Il y a eu un problème lors de la suppression de la voiture.",
      "delete": "Supprimer",
      "dropedCars": "Voitures abandonnées",
      "drivers": "Chauffeurs",
      "drivers_list": "Liste des chauffeurs",
      "edit_driver": "Modifier le chauffeur",
      "add_new_driver": "Ajouter un nouveau chauffeur",
      "full_name": "Nom complet",
      "email": "E-mail",
      "active": "Actif",
      "branch": "Branche",
      "profile_pic": "Photo de profil",
      "phone_number": "Numéro de téléphone",
      "mobile_number": "Numéro de mobile",
      "password": "Mot de passe",
      "choose_branch": "Choisir une branche",
      "add_driver": "Ajouter un chauffeur",
      "save_changes": "Enregistrer les modifications",
      "cancel": "Annuler",
      "delete": "Supprimer",
      "are_you_sure": "Êtes-vous sûr de vouloir supprimer",
      "error": "Erreur",
      "error_adding_driver": "Erreur lors de l'ajout du chauffeur",
      "error_updating_driver": "Erreur lors de la mise à jour du chauffeur",
      "driver_added": "Chauffeur ajouté avec succès",
      "driver_updated": "Chauffeur mis à jour avec succès",
      "driver_deleted": "Chauffeur supprimé avec succès",
      "driver_not_deleted": "Le chauffeur n'a pas été supprimé. Veuillez réessayer.",
      "all_fields_required": "Tous les champs sont obligatoires",
      "failed_fetch_data": "Échec de la récupération des données",
      "added": "Ajouté",
      "updated": "Mis à jour",
      "deleted": "Supprimé",
      "driver_details": "Détails du conducteur",
      "branches": "Branches",
      "addBranch": "+ Ajouter une branche",
      "addNewBranch": "Ajouter une nouvelle branche",
      "logo": "Logo",
      "name": "Nom",
      "address": "Adresse",
      "active": "Actif",
      "editBranch": "Modifier la branche",
      "editBranchTitle": "Modifier la branche: {{name}}",
      "viewBranch": "Voir",
      "deleteBranch": "Supprimer",
      "confirmDelete": "Êtes-vous sûr de vouloir supprimer {{name}} ?",
      "branchAdded": "Ajouté!",
      "branchUpdated": "Mis à jour!",
      "branchDeleted": "Supprimé!",
      "close": "Fermer",
      "cancel": "Annuler",
      "saveChanges": "Sauvegarder les modifications",
      "delete": "Supprimer",
      "logo": "Logo",
      "name": "Nom",
      "active": "Actif",
      "address": "Adresse",
      "branches": "Branches",
      "+_add_branch": "+ Ajouter une branche",
      "drivers": "Chauffeurs",
      "+_add_driver": "+ Ajouter un chauffeur",
      "profile": "Profil",
      "my_profile": "Mon Profil",
      "logout": "Se Déconnecter",
      "admin_name": "Nom de l'Admin",
      "change_password": "Changer le mot de passe",
      "all_rights_reserved": "Tous les droits réservés",
      "picked_up_cars": "Voitures Ramassées",
      "+_add_car": "+ Ajouter une Voiture",
      "change_banner": "Changer la bannière",
      "choose_banner": "Choisir la bannière",
      "update_banner": "Mettre à jour la bannière",
      "cancel": "Annuler",
      "select_banner": "Veuillez sélectionner une image de bannière!",
      "success": "Succès!",
      "banner_updated": "Bannière mise à jour avec succès.",
      "error": "Erreur!",
      "failed_update_banner": "Échec de la mise à jour de la bannière.",
      "login_here": "CONNEXION ICI",
      "enter_username": "Entrez le nom d'utilisateur",
      "enter_password": "Entrez le mot de passe",
      "remember_me": "Souviens-toi de moi",
      "forgot_password": "Mot de passe oublié?",
      "login": "CONNEXION",
      "please_provide_valid_input": "Veuillez fournir des informations valides",
      "forgot_password": "Mot de passe oublié",
      "enter_email": "Entrez votre email",
      "send_otp": "Envoyer OTP",
      "enter_otp": "Entrez OTP",
      "verify_otp": "Vérifier OTP",
      "reset_password": "Réinitialiser le mot de passe",
      "new_password": "Nouveau mot de passe",
      "confirm_password": "Confirmez le mot de passe",
      "please_provide_valid_email": "Veuillez fournir un email valide",
      "please_provide_valid_otp": "Veuillez fournir un OTP valide",
      "password_reset_successful": "Réinitialisation du mot de passe réussie",
      "passwords_do_not_match": "Les mots de passe ne correspondent pas"
    }
  },
  "ar": {
    "translation": {
      "image": "صورة",
      "fullName": "الاسم الكامل",
      "email": "البريد الإلكتروني",
      "active": "نشط",
      "branch": "فرع",
      "drivers": "السائقون",
      "error": "خطأ",
      "failedToFetchData": "فشل في جلب البيانات.",
      "noRecordsFound": "لم يتم العثور على سجلات.",
      "monthlyPickupStatistics": "إحصائيات الاستلام الشهرية",
      "pickedCars": "السيارات المستلمة",
      "droppedCars": "السيارات المسلّمة",
      "cars": "السيارات",
      "errorFetchingCars": "خطأ أثناء جلب بيانات السيارات.",
      "month": "الشهر",
      "pickups": "الاستلامات",
      "dashboard": "لوحة التحكم",
      "picked": "المستلمة",
      "dropped": "المسلّمة",
      "drivers": "السائقون",
      "branches": "الفروع",
      "view": "عرض",
      "edit": "تعديل",
      "dropIt": "إسقاط",
      "carBrandLogo": "شعار السيارة",
      "customerMobile": "رقم العميل",
      "carBrand": "ماركة السيارة",
      "licensePlate": "لوحة الترخيص",
      "driver": "السائق",
      "branch": "الفرع",
      "pickupTime": "وقت الاستلام",
      "dropOffTime": "وقت التسليم",
      "close": "إغلاق",
      "carDetails": "تفاصيل السيارة",
      "editCarDetails": "تعديل تفاصيل السيارة",
      "update": "تحديث",
      "cancel": "إلغاء",
      "updated": "تم التحديث!",
      "carDetailsUpdated": "تم تحديث تفاصيل السيارة.",
      "error": "خطأ!",
      "carUpdateError": "حدثت مشكلة أثناء تحديث السيارة.",
      "markCarDropped": "هل تريد وضع علامة على السيارة على أنها تم تسليمها؟",
      "markCarAsDropped": "هل تريد وضع علامة على السيارة على أنها تم تسليمها؟",
      "carMarkedDropped": "تم وضع علامة على السيارة على أنها تم تسليمها.",
      "doYouWantToMark": "هل تريد وضع علامة على السيارة ذات اللوحة",
      "asDropped": "على أنها تم تسليمها؟",
      "yesDropIt": "نعم، قم بتسليمها",
      "dropped": "تم تسليمها!",
      "carDropError": "حدثت مشكلة أثناء وضع علامة على السيارة بأنها تم تسليمها.",
      "view": "عرض",
      "edit": "تعديل",
      "dropIt": "إسقاط",
      "action": "إجراء",
      "allBranches": "جميع الفروع",
      "all": "الكل",
      "last1Week": "آخر أسبوع",
      "last1Month": "آخر شهر",
      "last3Months": "آخر 3 أشهر",
      "last6Months": "آخر 6 أشهر",
      "last1Year": "آخر سنة",
      "customDatesRange": "نطاق تواريخ مخصص",
      "exportToPDF": "تصدير إلى PDF",
      "pickedUpCars": "السيارات المستلمة",
      "addCar": "+ إضافة سيارة",
      "areYouSure": "هل أنت متأكد؟",
      "deleteCarText": "هل تريد حذف السيارة ذات لوحة الترخيص:",
      "yesDelete": "نعم، احذف",
      "cancel": "إلغاء",
      "deleted": "تم الحذف!",
      "carDeleted": "تم حذف السيارة.",
      "errorTitle": "خطأ",
      "fetchError": "فشل في جلب البيانات.",
      "deleteError": "كانت هناك مشكلة في حذف السيارة.",
      "delete": "حذف",
      "dropedCars": "السيارات المهجورة",
      "drivers": "السائقين",
      "drivers_list": "قائمة السائقين",
      "edit_driver": "تعديل السائق",
      "add_new_driver": "إضافة سائق جديد",
      "full_name": "الاسم الكامل",
      "email": "البريد الإلكتروني",
      "active": "نشط",
      "branch": "الفرع",
      "profile_pic": "صورة الملف الشخصي",
      "phone_number": "رقم الهاتف",
      "mobile_number": "رقم الجوال",
      "password": "كلمة المرور",
      "choose_branch": "اختر الفرع",
      "add_driver": "إضافة سائق",
      "save_changes": "حفظ التعديلات",
      "cancel": "إلغاء",
      "delete": "حذف",
      "file_too_large": "حجم الملف يتجاوز 1 ميغابايت",
      "are_you_sure": "هل أنت متأكد من حذف هذا السائق؟",
      "error": "خطأ",
      "error_adding_driver": "خطأ في إضافة السائق",
      "error_updating_driver": "خطأ في تحديث السائق",
      "driver_added": "تم إضافة السائق بنجاح",
      "driver_updated": "تم تحديث السائق بنجاح",
      "driver_deleted": "تم حذف السائق بنجاح",
      "driver_not_deleted": "لم يتم حذف السائق. يرجى المحاولة مرة أخرى.",
      "all_fields_required": "جميع الحقول مطلوبة",
      "failed_fetch_data": "فشل في جلب البيانات",
      "added": "تم الإضافة",
      "updated": "تم التحديث",
      "deleted": "تم الحذف",
      "driver_details": "تفاصيل السائق",
      "branches": "الفروع",
      "addBranch": "+ إضافة فرع",
      "addNewBranch": "إضافة فرع جديد",
      "logo": "الشعار",
      "name": "الاسم",
      "address": "العنوان",
      "active": "نشط",
      "editBranch": "تعديل الفرع",
      "editBranchTitle": "تعديل الفرع: {{name}}",
      "viewBranch": "عرض",
      "deleteBranch": "حذف",
      "confirmDelete": "هل أنت متأكد أنك تريد حذف {{name}}؟",
      "branchAdded": "تمت الإضافة!",
      "branchUpdated": "تم التحديث!",
      "branchDeleted": "تم الحذف!",
      "close": "إغلاق",
      "cancel": "إلغاء",
      "saveChanges": "حفظ التغييرات",
      "delete": "حذف",
      "logo": "الشعار",
      "name": "الاسم",
      "active": "نشط",
      "address": "العنوان",
      "branches": "الفروع",
      "+_add_branch": "+ إضافة فرع",
      "drivers": "السائقين",
      "+_add_driver": "+ إضافة سائق",
      "profile": "الملف الشخصي",
      "my_profile": "ملفي الشخصي",
      "logout": "تسجيل الخروج",
      "admin_name": "اسم المسؤول",
      "change_password": "تغيير كلمة المرور",
      "all_rights_reserved": "جميع الحقوق محفوظة",
      "picked_up_cars": "السيارات الملتقطة",
      "+_add_car": "+ إضافة سيارة",
      "change_banner": "تغيير البانر",
      "choose_banner": "اختر البانر",
      "update_banner": "تحديث البانر",
      "cancel": "إلغاء",
      "select_banner": "يرجى اختيار صورة البانر!",
      "success": "نجاح!",
      "banner_updated": "تم تحديث البانر بنجاح.",
      "error": "خطأ!",
      "failed_update_banner": "فشل في تحديث البانر.",
      "login_here": "تسجيل الدخول هنا",
      "enter_username": "أدخل اسم المستخدم",
      "enter_password": "أدخل كلمة المرور",
      "remember_me": "تذكرني",
      "forgot_password": "نسيت كلمة المرور؟",
      "login": "تسجيل الدخول",
      "please_provide_valid_input": "يرجى توفير مدخلات صالحة",
      "forgot_password": "نسيت كلمة المرور",
      "enter_email": "أدخل بريدك الإلكتروني",
      "send_otp": "إرسال رمز التحقق",
      "enter_otp": "أدخل رمز التحقق",
      "verify_otp": "تحقق من رمز التحقق",
      "reset_password": "إعادة تعيين كلمة المرور",
      "new_password": "كلمة المرور الجديدة",
      "confirm_password": "تأكيد كلمة المرور",
      "please_provide_valid_email": "يرجى تقديم بريد إلكتروني صالح",
      "please_provide_valid_otp": "يرجى تقديم رمز تحقق صالح",
      "password_reset_successful": "تمت إعادة تعيين كلمة المرور بنجاح",
      "passwords_do_not_match": "كلمات المرور غير متطابقة"
    }
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
  });

export default i18n;
