import React, { useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner'
import { useNavigate, useParams } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import axios from "../../config/server";



const RequestCarPage = () => {
    const [language, setLanguage] = useState("en");
    const { branchId, serialNumber, plateNumber } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [showNext, setShowNext] = useState(false)
    const [data, setData] = useState({})
    const [status, setStatus] = useState(false)
    const navigate = useNavigate()
    const handleLanguageChange = (event) => {
      setLanguage(event.target.value);
    };
    useEffect(() => {
      const validateCarDetails = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get("/cars/validate", {
            params: { branchId, serialNumber, plateNumber },
          });
          if (response.status === 200) {
            setIsLoading(false);
            
            setData(response?.data?.data)
          }
        } catch (error) {
          alert(error.response?.data?.message || "Validation failed");
        } finally {
          setIsLoading(false);
        }
      };
  
      validateCarDetails();
    }, [branchId, serialNumber, plateNumber]);

    const prepareCar = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post("/cars/prepare", {
          branchId,
          serialNumber,
          plateNumber
        });
        setIsLoading(false)
        setStatus(true)
        setShowNext(true);
        alert(response.data.message);
      } catch (error) {
        alert(error.response?.data?.message || "Failed to prepare car");
      } finally {
        setIsLoading(false);
      }
    };
    const logo = data?.carBrandLogo?.data;
    const contentType = data?.carBrandLogo?.contentType;
    const logoBase64 = logo ? `data:${contentType};base64,${logo}` : "/assets/images/logo.png";
    const handleNavigateToFeedback = () => {
      navigate(`/customer/feedback/${branchId}/${serialNumber}/${plateNumber}`, {
        state : {
          branchId, 
          serialNumber,
          plateNumber
        }
      })
    }
  return (
    <div className="container">
      <div className="language-selector">
            {
                  language === 'ar' ?
                  <button className='btn language-btn' onClick={() => handleLanguageChange('en')}>English</button> :
                  <button className='btn language-btn' onClick={() => handleLanguageChange('ar')}>ARABIC</button>
                }
        </div>
        <img src="/assets/images/logo.png" alt="Hilton Logo" className="footer-logo" />
        <img src={logoBase64} style={{width: "80px"}} alt="Hilton Logo" className="footer-logo" />
      <main className="main-content">
        <h1 className="title blue-shade">REQUEST CAR</h1>
        <p className="description blue-shade">PRESS TO REQUEST YOUR CAR</p>
        <Oval
            visible={isLoading}
            height="40"
            width="40"
            color="gray"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
            {
                !isLoading && status &&
                <CiCircleCheck color="green" fontSize={50}/>
            }
            {
                !isLoading && !status &&
                 <button className="button" onClick={prepareCar} style={{border: "1px solid transparrent", borderRadius: "50px"}}>PREPARE MY CAR</button>
            }
            {
                !isLoading && status && showNext &&
                 <button className="button mt-2" onClick={handleNavigateToFeedback} style={{border: "1px solid transparrent", borderRadius: "50px"}}>Next</button>
            }
      </main>

      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 3rem;
          height: 100vh;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .language-selector {
          width: 100%;
        }
        .language-btn{
          color: #0A1E3F;
          border: none !important;
        }
        .language-dropdown {
            padding: 5px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            background : white;
        }

        .header {
          margin-top: 20px;
        }

        .brand {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
        }

        .main-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .title {
          font-size: 20px;
          font-weight: bold;
          margin: 20px 0 10px;
        }

        .description {
          font-size: 16px;
          margin-bottom: 20px;
        }

        .button {
          background-color: #001f3f;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .button:hover {
          background-color: #0056b3;
        }

        .footer {
          margin-bottom: 20px;
        }

        .footer-logo {
          max-width: 100px;
          height: auto;
        }

        @media (min-width: 768px) {
          .container {
            padding: 40px;
          }

          .brand {
            font-size: 32px;
          }

          .title {
            font-size: 28px;
          }

          .description {
            font-size: 18px;
          }

          .button {
            font-size: 18px;
            padding: 15px 30px;
          }

          .footer-logo {
            max-width: 120px;
          }
        }
      `}</style>
    </div>
  );
};

export default RequestCarPage;
