import React, { useState, useEffect, useCallback } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import Swal from 'sweetalert2';
import axios from '../../config/server';
import { Eye, Trash2, Edit, CheckCircle } from 'lucide-react';
import { Button, Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const getCarColumns = (t) => [
    { header: t('customerMobile'), accessor: "customerMobile", type: "text" }, // Customer Mobile
    { header: t('carBrandLogo'), accessor: "carBrandLogo", type: "image" },    // Car Brand Logo
    { header: t('carBrand'), accessor: "carBrand", type: "text" },              // Car Brand
    { header: t('licensePlate'), accessor: "licensePlate", type: "text" },      // License Plate
    { header: t('driver'), accessor: "driver", type: "text" },                   // Driver
    { header: t('branch'), accessor: "branch", type: "text" },                   // Branch
    { header: t('pickupTime'), accessor: "pickupTime", type: "date" },          // Pickup Time
    { header: t('dropOffTime'), accessor: "dropOffTime", type: "date" },        // Drop Time
];

const PickedCars = () => {
    const [data, setData] = useState([]); // Manage the car data in state
    const [branches, setBranches] = useState([]); // Manage the car data in state
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('ALL');
    const [selectedBranch, setSelectedBranch] = useState("ALL");
    const [customDateRange, setCustomDateRange] = useState({ startDate: null, endDate: null });
    const { t } = useTranslation();
    const carColumns = getCarColumns(t);
    // Fetch car data from backend on component mount
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };
    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };
    useEffect(() => {
        fetchCars();
        fetchBranches()
    }, []); // Empty array ensures this runs once when the component is mounted
    useEffect(() => {
        selectedFilter === "custom" && applyCustomDateRange()
    }, [customDateRange?.startDate, customDateRange?.endDate, data, selectedBranch]); 
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        if (filter === 'custom') {
            applyCustomDateRange(customDateRange.startDate, customDateRange.endDate);
        }
         else {
            resetCustomDateRange()
            applyFilters(filter, selectedBranch);
        }
    };
    

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (value === '') {
            setCustomDateRange(prevState => ({
                ...prevState,
                [name]: null,
            }));
            return;
        }
        setCustomDateRange(prevState => {
            const updatedRange = { ...prevState, [name]: value };
            // Trigger filtering when dates change
            return updatedRange;
        });
    };
    
    

    const handleBranchChange = (branch) => {
        setSelectedBranch(branch);
        applyFilters(selectedFilter, branch);
    };

    // Actions for View, Edit, Delete, and Drop it
    const actions = [
        {
            label: "View",
            icon: <Eye size={16} />,
            onClick: (row) => showViewAlert(row),
        },
        {
            label: "Edit",
            icon: <Edit size={16} />,
            onClick: (row) => editCar(row),
        },
        // {
        //     label: "Delete",
        //     icon: <Trash2 size={16} />,
        //     onClick: (row) => deleteCar(row),
        // },
        {
            label: "Drop it",
            icon: <CheckCircle size={16} />,
            onClick: (row) => dropCar(row),
        },
    ];
    const applyFilters = (timeFilter, branchFilter) => {
        const now = moment();
        const filtered = data.filter((car) => {
            const pickupTime = moment(car.pickupTime);
            const timeMatch = (() => {
                switch (timeFilter) {
                    case "1_WEEK":
                        return pickupTime.isSame(now, "week");
                    case "1_MONTH":
                        return pickupTime.isSame(now, "month");
                    case "3_MONTHS":
                        return pickupTime.isBetween(
                            now.clone().subtract(3, "months").startOf("month"),
                            now.endOf("month"),
                            null,
                            "[]"
                        );
                    case "6_MONTHS":
                        return pickupTime.isBetween(
                            now.clone().subtract(6, "months").startOf("month"),
                            now.endOf("month"),
                            null,
                            "[]"
                        );
                    case "1_YEAR":
                        return pickupTime.isSame(now, "year");
                    default:
                        return true;
                }
            })();

            const branchMatch =
                branchFilter === "ALL" || car.branch?._id === branchFilter;

            return timeMatch && branchMatch;
        });

        setFilteredData(filtered);
    };

    const applyCustomDateRange = () => {
        const { startDate, endDate } = customDateRange;    
        const start = moment(startDate);
        const end = moment(endDate);
    
        // Ensure the end date is inclusive
        const filtered = data.filter((car) => {
            const pickupTime = moment(car.pickupTime);
            const branchMatch =
                selectedBranch === "ALL" || car.branch?._id === selectedBranch;
            if(startDate === null && branchMatch) return true;
            if(pickupTime.isSameOrAfter(start, 'day') && endDate === null && branchMatch) return true;
            return pickupTime.isSameOrAfter(start, 'day') && pickupTime.isSameOrBefore(end, 'day') && branchMatch;
        });
    
        setFilteredData(filtered);
    }
    
    const resetCustomDateRange = () => {
        setCustomDateRange({ startDate: null, endDate: null });
        setFilteredData(data); // Reset the filtered data to show all cars
    };    



    const tableData = filteredData?.filter(x => x?.status === "picked")?.map(x => {
        const logo = x?.carBrandLogo?.data;
        const contentType = x?.carBrandLogo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        return {
            name: x?.name,
            branch: x?.branch?.name,
            branchId: x?.branch?._id,
            carBrandLogo: logoBase64,
            driver: x?.driver?.name,
            customerMobile: x?.customerMobile,
            carBrand: x?.carBrand,
            licensePlate: x?.licensePlate,
            pickupTime: new Date(x?.pickupTime).toLocaleDateString() + " " + new Date(x?.pickupTime).toLocaleTimeString(),
            _id: x?._id,
            status: x?.status,
            dropOffTime: x?.dropOffTime ? new Date(x?.dropOffTime)?.toLocaleDateString() + " " + new Date(x?.dropOffTime).toLocaleTimeString() : "-----"
        };
    });
    const validateStringAsBase64 = (str) => {
        try {
            // Check if the string is a valid base64 image string (simplified check)
            const regex = /^data:image\/[a-zA-Z]+;base64,/;
            return regex.test(str);
        } catch (error) {
            return false;
        }
    };
    const exportPDF = (data) => {
        const doc = new jsPDF("portrait", "mm", "a4");
        // const pageWidth = 210; // A4 width in mm
        // const pageHeight = 297; // A4 height in mm
        // const margin = 5; // Margin for the rounded border
        // doc.roundedRect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin, 5, 5); // Adds rounded border with a radius of 5
        // Add RZANA logo
        doc.addImage('/assets/images/logo.png', 'PNG', 10, 7, 40, 20); // Adjust position/size as needed

        // Add Hilton text with border
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        const branch = branches?.filter(x => x?._id?.toString() === selectedBranch)[0]
        const logo = branch?.logo?.data;
        const contentType = branch?.logo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        const pageWidth = doc.internal.pageSize.width;
        const logoWidth = 40;
        const xPosition = (pageWidth - logoWidth) / 2;

        doc.addImage(logoBase64 ? logoBase64 : '/assets/images/logo.png', 'PNG', xPosition, 10, logoWidth, 15);
        // doc.addImage(logoBase64, 105, 22, { align: "center" }); // Center Hilton text
        doc.rect(xPosition - 1.5, 8.5, 43, 18); // Draw rectangle around Hilton

        let fromDate, toDate;

        if (selectedFilter === "ALL") {
            fromDate = "All";
            toDate = "All";
        } else {
            toDate = moment().format("DD/MM/YYYY"); // Current date as "TO"
            switch (selectedFilter) {
                case "1_WEEK":
                    fromDate = moment().subtract(1, 'week').format("DD/MM/YYYY");
                    break;
                case "1_MONTH":
                    fromDate = moment().subtract(1, 'month').format("DD/MM/YYYY");
                    break;
                case "3_MONTHS":
                    fromDate = moment().subtract(3, 'months').format("DD/MM/YYYY");
                    break;
                case "6_MONTHS":
                    fromDate = moment().subtract(6, 'months').format("DD/MM/YYYY");
                    break;
                case "1_YEAR":
                    fromDate = moment().subtract(1, 'year').format("DD/MM/YYYY");
                    break;
                case "custom":
                    toDate = customDateRange?.endDate ? moment(customDateRange?.endDate)?.format("DD/MM/YYYY") : moment().add(1, 'year').format("DD/MM/YYYY")
                    fromDate = customDateRange?.startDate ? moment(customDateRange?.startDate)?.format("DD/MM/YYYY") : moment().subtract(1, 'year').format("DD/MM/YYYY");
                    break;
                default:
                    fromDate = "All";
            }
        }
        // Add From and To dates
        doc.setFontSize(10);
        doc.text("FROM :", 150, 16);
        doc.text(fromDate, 170, 16);
        doc.text("TO :", 150, 22);
        doc.text(toDate, 170, 22);

        // Horizontal Divider
        doc.setDrawColor(0);
        doc.line(10, 30, 200, 30);

        // Progress Report Heading
        doc.setFontSize(14);
        doc.text("PROGRESS SHEET", 105, 40, { align: "center" });

        // Table Data Preparation
        const tableColumns = [
            "S. No.",
            "Car Brand",
            "Customer No.",
            "Plate No.",
            "Date",
            "Pickup Time",
            "Drop Off Time",
        ];

        const tableRows = filteredData?.map((item, index) => {
            const logo = item?.carBrandLogo?.data;
            const contentType = item?.carBrandLogo?.contentType;
            const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
            const isValidBase64 = validateStringAsBase64(logoBase64);
            const carBrandLogo = isValidBase64 ? logoBase64 : '/assets/images/cars/alto.png';
            const dropOffTime = item.dropOffTime ? moment(item.dropOffTime).format('MM/DD/YYYY hh:mm A') : "----"
            return {
                rowData: [
                    String(index + 1).padStart(2, "0"), // S. No
                    " ", // Placeholder for image
                    item.customerMobile,
                    item.licensePlate,
                    moment(item.pickupTime).format("MM/DD/YYYY"),
                    moment(item.pickupTime).format('hh:mm A'),
                    dropOffTime
                ],
                carBrandLogo,
            };
        });


        // Add Table
        doc.autoTable({
            head: [tableColumns],
            body: tableRows.map((row) => row.rowData),
            startY: 50,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255], // White background
                textColor: [0, 0, 0],       // Black text
                fontStyle: "bold",         // Bold header text
            },
            styles: {
                fontSize: 10,
                cellPadding: 3,
                lineColor: [0, 0, 0],
                lineWidth: 0.5,
            },
            didDrawCell: function (data) {
                if (data.column.index === 1 && data.cell.section === 'body') {
                    const logoBase64 = tableRows[data.row.index].carBrandLogo;
                    const cellWidth = data.cell.width;
                    const cellHeight = data.cell.height;
                    const imgSize = 9;
                    const x = data.cell.x + (cellWidth - imgSize) / 2;
                    const y = data.cell.y + (cellHeight - imgSize) / 2;

                    doc.setDrawColor(0);
                    doc.setFillColor(255, 255, 255);
                    doc.roundedRect(x, y, imgSize, imgSize, imgSize / 2, imgSize / 2, 'F');
                    doc.addImage(logoBase64, 'PNG', x, y, imgSize, imgSize, undefined, 'S');
                }
            }
        });


        // Save PDF
        doc.save("Progress_Sheet.pdf");
    };

    
    window.handleLicensePlateInput = (event) => {
        const input = event.target;
        let value = input.value; // Keep user-entered case
        const letterPattern = /^[A-Za-z]{1,3}$/; // Pattern for letters (1-3)
        const numberPattern = /^\d{1,4}$/; // Pattern for numbers (1-4)
    
        // Remove invalid characters
        value = value.replace(/[^A-Za-z0-9]/g, ''); // Allow only letters and numbers
    
        if (value.length === 0) {
            input.value = value; // Allow empty input
            return;
        }
    
        // Determine the format based on the first character
        const isLetterFirst = /^[A-Za-z]/.test(value[0]);
    
        if (isLetterFirst) {
            // First character is a letter; format as AAA-1234
            const [letters, numbers] = value.split(/(\d+)/); // Split letters and numbers
            const formattedValue = letters.slice(0, 3) + (numbers ? `-${numbers.slice(0, 4)}` : '');
            input.value = formattedValue;
        } else {
            // First character is a number; format as 1234-AAA
            const [numbers, letters] = value.split(/([A-Za-z]+)/); // Split numbers and letters
            const formattedValue = numbers.slice(0, 4) + (letters ? `-${letters.slice(0, 3)}` : '');
            input.value = formattedValue;
        }
    };
    
    // Show details for a car (View action)
    // Show details for a car (View action)
    const showViewAlert = (row) => {
    
        const htmlContent = `<div class="text-start">
            <p><strong>${t('customerMobile')}:</strong> ${row.customerMobile}</p>
            <p><strong>${t('carBrand')}:</strong> ${row.carBrand}</p>
            <p><strong>${t('licensePlate')}:</strong> ${row.licensePlate}</p>
            <p><strong>${t('driver')}:</strong> ${row.driver}</p>
            <p><strong>${t('branch')}:</strong> ${row.branch}</p>
            <p><strong>${t('pickupTime')}:</strong> ${new Date(row.pickupTime).toLocaleString()}</p>
            <p><strong>${t('dropOffTime')}:</strong> ${row.dropOffTime ? new Date(row.dropOffTime).getFullYear() > 2000 ? new Date(row.dropOffTime).toLocaleDateString() : "--------" : "-----"}</p>
        </div>`;
    
        Swal.fire({
            title: `<h3 style="color: #0A1E3F;">${t('carDetails')}: ${row.carBrand} (${row.licensePlate})</h3>`,
            html: htmlContent,
            icon: 'info',
            confirmButtonText: t('close'),
            didOpen: () => {
                const confirmBTN = document.querySelector('.swal2-confirm');
                const title = document.querySelector('.swal2-title');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        });
    };

    // Edit a car's details
    const editCar = (row) => {
        Swal.fire({
            title: t('editCarDetails'),
            html: `
                <input id="carBrand" class="swal2-input custom-placeholder" placeholder="${t('carBrand')}" value="${row.carBrand}" required>
                <input id="licensePlate" class="swal2-input custom-placeholder" placeholder="${t('licensePlate')}" value="${row.licensePlate}" required>
            `,
            confirmButtonText: t('update'),
            cancelButtonText: t('cancel'),
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            preConfirm: async () => {
                const carBrand = document.getElementById('carBrand').value;
                const licensePlate = document.getElementById('licensePlate').value;
                const updatedCar = { ...row, carBrand, licensePlate, dropOffTime: null };

                try {
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars()
                    Swal.fire(t('updated'), t('carDetailsUpdated'), 'success');
                } catch (error) {
                    Swal.fire(t('error'), t('carUpdateError'), 'error');
                }
            }
        });
    };

    // Delete a car
    const deleteCar = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the car with License Plate: ${row.licensePlate}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`/api/cars/${row._id}`);
                    setData(data.filter(car => car._id !== row._id));
                    Swal.fire('Deleted!', 'The car has been deleted.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue deleting the car.', 'error');
                }
            }
        });
    };

    // Drop car (mark as dropped)
    const dropCar = (row) => {
    
        Swal.fire({
            title: t('markCarAsDropped'), // Using translation for title
            text: t('doYouWantToMark') + ` ${row.licensePlate} ${t('asDropped')}`, // Using translation for text
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('yesDropIt'), // Using translation for confirm button
            cancelButtonText: t('cancel'), // Using translation for cancel button
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const content = document.querySelector('.swal2-html-container');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
                if (content) {
                    content.style.color = '#0A1E3F'; // Text color
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const updatedCar = { ...row, status: "droped", dropOffTime: moment().tz('UTC').format() };
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars();
                    Swal.fire(t('dropped'), t('carMarkedDropped'), 'success'); // Using translation for success message
                } catch (error) {
                    Swal.fire(t('error'), t('carDropError'), 'error'); // Using translation for error message
                }
            }
        });
    };
    // Add a new car
    const addCar = () => {
        Swal.fire({
            title: t('addNewCar'), // Using translation for title
            html: `
                <input id="newCarBrand" class="swal2-input blue-shade custom-placeholder" placeholder="${t('carBrand')}" required>
                <div id="logoDropdownContainer"></div> <!-- Logo dropdown container -->
                <input id="newLicensePlate" class="swal2-input blue-shade custom-placeholder" placeholder="${t('licensePlate')}" maxlength="8" required oninput="handleLicensePlateInput(event)" />
                <input id="newSerialNumber" class="swal2-input blue-shade custom-placeholder" placeholder="${t('serialNumber')}" required>
                <input id="newCustomerMobile" class="swal2-input blue-shade custom-placeholder" placeholder="${t('customerMobile')}" required>
                <div id="branchSelectContainer"></div> <!-- Branch dropdown container -->
            `,
            focusConfirm: false,
            didOpen: () => {
                // Render Branch Dropdown
                const branchSelectContainer = document.getElementById('branchSelectContainer');
                if (branchSelectContainer) {
                    const branchSelect = (
                        <Form.Select
                            className="mx-auto"
                            id="newBranch"
                            defaultValue=""
                            required
                            style={{
                                width: "58%",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                marginTop: "10px",
                                backgroundColor: "white",
                                color: "#0A1E3F",
                                border: "1px solid lightgray",
                            }}
                        >
                            <option value="" disabled>Choose Branch</option>
                            {branches.map((branch, index) => (
                                <option value={branch._id} key={index}>{branch.name}</option>
                            ))}
                        </Form.Select>
                    );
                    ReactDOM.render(branchSelect, branchSelectContainer);
                }

                // Render Logo Dropdown
                const logoDropdownContainer = document.getElementById('logoDropdownContainer');
                if (logoDropdownContainer) {
                    const logos = [
                        { id: 'alto', src: '/assets/images/cars/alto.png', alt: 'Alto' },
                        { id: 'sazuki', src: '/assets/images/cars/sazuki.png', alt: 'Sazuki' },
                        { id: 'ford', src: '/assets/images/cars/ford.png', alt: 'Ford' },
                    ];

                    const logoDropdown = (
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <div
                                className="custom-dropdown"
                                style={{
                                    width: '58%',
                                    margin: '0 auto',
                                    zIndex: "1000",
                                    padding: '10px',
                                    border: '1px solid lightgray',
                                    borderRadius: '5px',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                }}
                            >
                                <span id="selectedLogoText" style={{ color: '#0A1E3F' }}>Choose Brand Logo</span>
                                <div
                                    className="dropdown-options"
                                    style={{
                                        display: 'none',
                                        position: 'absolute',
                                        top: '100%',
                                        left: '0',
                                        right: '0',
                                        zIndex: 10,
                                        backgroundColor: 'white',
                                        border: '1px solid lightgray',
                                        borderRadius: '5px',
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                    }}
                                    id="logoOptions"
                                >
                                    {logos.map((logo) => (
                                        <div
                                            key={logo.id}
                                            className="dropdown-option"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '5px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                // Update the selected logo
                                                const selectedLogoText = document.getElementById('selectedLogoText');
                                                if (selectedLogoText) {
                                                    selectedLogoText.innerHTML = `<img src="${logo.src}" alt="${logo.alt}" style="width:20px; height:20px; margin-right:5px;"> ${logo.alt}`;
                                                }
                                                const selectedLogoFile = document.getElementById('selectedLogoFile');
                                                if (selectedLogoFile) {
                                                    selectedLogoFile.value = logo.src;
                                                }

                                                // Hide options
                                                const logoOptions = document.getElementById('logoOptions');
                                                if (logoOptions) logoOptions.style.display = 'none';
                                            }}
                                        >
                                            <img
                                                src={logo.src}
                                                alt={logo.alt}
                                                style={{ width: '30px', height: '35px', marginRight: '10px' }}
                                            />
                                            {logo.alt}
                                        </div>
                                    ))}
                                </div>
                                <input type="hidden" id="selectedLogoFile" value="" />
                            </div>
                        </div>
                    );
                    ReactDOM.render(logoDropdown, logoDropdownContainer);

                    // Toggle dropdown visibility
                    const dropdown = document.querySelector('.custom-dropdown');
                    if (dropdown) {
                        dropdown.addEventListener('click', () => {
                            const logoOptions = document.getElementById('logoOptions');
                            if (logoOptions) {
                                logoOptions.style.display = logoOptions.style.display === 'none' ? 'block' : 'none';
                            }
                        });
                    }
                }
            },
            confirmButtonText: t('pickUp'), 
            cancelButtonText: t('cancel'),
            preConfirm: async () => {
                const carBrand = document.getElementById('newCarBrand').value;
                const licensePlate = document.getElementById('newLicensePlate').value?.split("-")?.join("");
                const customerMobile = document.getElementById('newCustomerMobile').value;
                const serialNumber = document.getElementById('newSerialNumber').value;
                const branch = document.getElementById('newBranch').value;
                const selectedLogoFile = document.getElementById('selectedLogoFile').value;

                const licensePlateInput = document.getElementById('newLicensePlate').value;
                // Validation
                if (!carBrand || !licensePlate || !customerMobile || !branch || !selectedLogoFile) {
                    Swal.showValidationMessage(t('pleaseFillFields'));
                    return false;
                }

                try {
                    const logoFile = await fetch(selectedLogoFile)
                        .then((response) => response.blob())
                        .then((blob) => new File([blob], "car_logo.png", { type: blob.type }));
                    const formData = new FormData();
                    formData.append('carBrand', carBrand);
                    formData.append('licensePlate', licensePlate);
                    formData.append('customerMobile', customerMobile);
                    formData.append('serialNumber', serialNumber);
                    formData.append('branch', branch);
                    formData.append('logo', logoFile);

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/cars/add`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    fetchCars();
                    Swal.fire(t('added'), t('newCarAdded'), 'success'); // Using translation for success message
                } catch (error) {
                    Swal.fire(t('error'), t('addCarError'), 'error'); 
                }
            },
        });
    };
    return (
        <div>
            <Layout active="cars" sub_active="Picked" isAdmin={true}>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <Form.Select
                            onChange={(e) => handleBranchChange(e.target.value)}
                            value={selectedBranch}
                            className="me-2"
                            style={{ background: "#0A1E3F", height: "40px" }}
                        >
                            <option value="ALL">{t('allBranches')}</option>
                            {branches.map((branch) => (
                                <option key={branch._id} value={branch._id}>
                                    {branch.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select
                            onChange={(e) => handleFilterChange(e.target.value)}
                            value={selectedFilter}
                            className="me-2"
                            style={{ background: "#0A1E3F", height: "40px" }}
                        >
                            <option value="ALL">{t('all')}</option>
                            <option value="1_WEEK">{t('last1Week')}</option>
                            <option value="1_MONTH">{t('last1Month')}</option>
                            <option value="3_MONTHS">{t('last3Months')}</option>
                            <option value="6_MONTHS">{t('last6Months')}</option>
                            <option value="1_YEAR">{t('last1Year')}</option>
                            <option value="custom">{t('customDatesRange')}</option>
                        </Form.Select>
                        {selectedFilter === "custom" && (
                            <div className="d-flex">
                                <input
                                    type="date"
                                    name="startDate"
                                    value={customDateRange.startDate || ''}
                                    onChange={handleDateChange}
                                    style={{
                                        background : '#0A1E3F'
                                    }}
                                    className="form-control me-2"
                                    max={moment().format('YYYY-MM-DD')} // Prevent future dates
                                />
                                <input
                                    type="date"
                                    name="endDate"
                                    style={{
                                        background : '#0A1E3F'
                                    }}
                                    value={customDateRange.endDate || ''}
                                    onChange={handleDateChange}
                                    className="form-control"
                                />
                            </div>
                        )}
                    </div>
                    <Button onClick={() => exportPDF(tableData)} className="bg-blue">
                        {t('exportToPDF')}
                    </Button>
                </div>
                <CardSection
                    title={t('pickedUpCars')}
                    buttonText={t('addCar')}
                    onClickEvent={addCar}
                >
                    <GenericTable
                        data={tableData}
                        columns={carColumns}
                        action={true}
                        actions={actions}
                    />
                </CardSection>
            </Layout>
        </div>
    );
};

export default PickedCars;
