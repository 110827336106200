import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { useTranslation } from 'react-i18next';
import { getCarColumns } from '../admin/PickedCars';
import axios from '../../config/server';
import Swal from 'sweetalert2';
import { Trash2 } from 'lucide-react';

const DropedCards = () => {
    const [data, setData] = useState([]); // Manage the car data in state
    const [branches, setBranches] = useState([]); // Manage the car data in state
    const { t } = useTranslation(); // Use translation hook to access translations
    const carColumns = getCarColumns(t); // Pass translation function to columns function
    
    // Fetch car data from backend on component mount
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };

    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire(t('errorTitle'), t('fetchError'), 'error');
        }
    };

    useEffect(() => {
        fetchCars();
        fetchBranches();
    }, []); // Empty array ensures this runs once when the component is mounted

    // Delete a car
    const deleteCar = (row) => {
        Swal.fire({
            title: t('areYouSure'),
            text: `${t('deleteCarText')} ${row?.licensePlate}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('yesDelete'),
            cancelButtonText: t('cancel'),
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                const content = document.querySelector('.swal2-html-container');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
                if (content) {
                    content.style.color = '#0A1E3F'; // Title color
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_API_URL}/cars/${row?._id}`);
                    fetchCars();
                    Swal.fire(t('deleted'), t('carDeleted'), 'success');
                } catch (error) {
                    Swal.fire(t('errorTitle'), t('deleteError'), 'error');
                }
            }
        });
    };

    // Actions for View, Edit, Delete, and Drop it
    const actions = [
        {
            label: t('delete'),
            icon: <Trash2 size={16} />,
            onClick: (row) => deleteCar(row),
        },
    ];

    const tableData = data?.filter(x => x?.status === "droped")?.map(x => {
        const logo = x?.carBrandLogo?.data;
        const contentType = x?.carBrandLogo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        return {
            name: x?.name,
            branch: x?.branch?.name,
            branchId: x?.branch?._id,
            carBrandLogo: logoBase64,
            driver: x?.driver?.name,
            customerMobile: x?.customerMobile,
            carBrand: x?.carBrand,
            licensePlate: x?.licensePlate,
            pickupTime: new Date(x?.pickupTime).toLocaleDateString() + " " + new Date(x?.pickupTime).toLocaleTimeString(),
            _id: x?._id,
            status: x?.status,
            dropOffTime: x?.dropOffTime ? new Date(x?.dropOffTime)?.toLocaleDateString() + " " + new Date(x?.dropOffTime).toLocaleTimeString() : "-----"
        };
    });

    return (
        <div>
            <Layout active={'Cars'} sub_active={'Droped'} isAdmin={true}>
                <CardSection title={t('dropedCars')}>
                    <GenericTable data={tableData} columns={carColumns} actions={actions} />
                </CardSection>
            </Layout>
        </div>
    );
}

export default DropedCards;
