import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { useTranslation } from 'react-i18next';
import { getCarColumns } from '../admin/PickedCars';
import Swal from 'sweetalert2';
import axios from '../../config/server';
import { Eye, Trash2, Edit, CheckCircle } from 'lucide-react';
import ReactDOM from 'react-dom';


const DriverPickedCars = () => {
    const [data, setData] = useState([]); // Manage the car data in state
    const [branches, setBranches] = useState([]); // Manage the car data in state
    const user = JSON.parse(localStorage.getItem('user'));
    const { t } = useTranslation(); 
    const carColumns = getCarColumns(t)
    // Fetch car data from backend on component mount
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };
    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };
    useEffect(() => {
        fetchCars();
        fetchBranches()
    }, []); // Empty array ensures this runs once when the component is mounted

    // Show details for a car (View action)
    const showViewAlert = (row) => {
    
        const htmlContent = `<div class="text-start">
            <p><strong>${t('customerMobile')}:</strong> ${row.customerMobile}</p>
            <p><strong>${t('carBrand')}:</strong> ${row.carBrand}</p>
            <p><strong>${t('licensePlate')}:</strong> ${row.licensePlate}</p>
            <p><strong>${t('driver')}:</strong> ${row.driver}</p>
            <p><strong>${t('branch')}:</strong> ${row.branch}</p>
            <p><strong>${t('pickupTime')}:</strong> ${new Date(row.pickupTime).toLocaleString()}</p>
            <p><strong>${t('dropOffTime')}:</strong> ${row.dropOffTime ? new Date(row.dropOffTime).getFullYear() > 2000 ? new Date(row.dropOffTime).toLocaleDateString() : "--------" : "-----"}</p>
        </div>`;
    
        Swal.fire({
            title: `<h3 style="color: #0A1E3F;">${t('carDetails')}: ${row.carBrand} (${row.licensePlate})</h3>`,
            html: htmlContent,
            icon: 'info',
            confirmButtonText: t('close'),
            didOpen: () => {
                const confirmBTN = document.querySelector('.swal2-confirm');
                const title = document.querySelector('.swal2-title');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        });
    };
    

    // Edit a car's details
    const editCar = (row) => {
        Swal.fire({
            title: t('editCarDetails'),
            html: `
                <input id="carBrand" class="swal2-input custom-placeholder" placeholder="${t('carBrand')}" value="${row.carBrand}" required>
                <input id="licensePlate" class="swal2-input custom-placeholder" placeholder="${t('licensePlate')}" value="${row.licensePlate}" required>
            `,
            confirmButtonText: t('update'),
            cancelButtonText: t('cancel'),
            didOpen: () => {
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            focusConfirm: false,
            preConfirm: async () => {
                const carBrand = document.getElementById('carBrand').value;
                const licensePlate = document.getElementById('licensePlate').value;
                const updatedCar = { ...row, carBrand, licensePlate, dropOffTime: null };
    
                try {
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars();
                    Swal.fire(t('updated'), t('carDetailsUpdated'), 'success');
                } catch (error) {
                    Swal.fire(t('error'), t('carUpdateError'), 'error');
                }
            }
        });
    };
    

    // Delete a car
    const deleteCar = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the car with License Plate: ${row.licensePlate}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`/api/cars/${row._id}`);
                    setData(data.filter(car => car._id !== row._id));
                    Swal.fire('Deleted!', 'The car has been deleted.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue deleting the car.', 'error');
                }
            }
        });
    };

    // Drop car (mark as dropped)
    const dropCar = (row) => {
        Swal.fire({
            title: t('markCarDropped'),
            text: `${t('doYouWantToMark')} ${t('licensePlate')}: ${row.licensePlate} ${t('asDropped')}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('yesDropIt'),
            cancelButtonText: t('cancel'),
            didOpen: () => {
                const confirmBTN = document.querySelector('.swal2-confirm');
                const title = document.querySelector('.swal2-title');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const updatedCar = { ...row, status: "dropped", dropOffTime: Date.now() };
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars();
                    Swal.fire(t('dropped'), t('carMarkedDropped'), 'success');
                } catch (error) {
                    Swal.fire(t('error'), t('carDropError'), 'error');
                }
            }
        });
    };
    

    window.handleLicensePlateInput = (event) => {
        const input = event.target;
        let value = input.value; // Keep user-entered case
        const letterPattern = /^[A-Za-z]{1,3}$/; // Pattern for letters (1-3)
        const numberPattern = /^\d{1,4}$/; // Pattern for numbers (1-4)
    
        // Remove invalid characters
        value = value.replace(/[^A-Za-z0-9]/g, ''); // Allow only letters and numbers
    
        if (value.length === 0) {
            input.value = value; // Allow empty input
            return;
        }
    
        // Determine the format based on the first character
        const isLetterFirst = /^[A-Za-z]/.test(value[0]);
    
        if (isLetterFirst) {
            // First character is a letter; format as AAA-1234
            const [letters, numbers] = value.split(/(\d+)/); // Split letters and numbers
            const formattedValue = letters.slice(0, 3) + (numbers ? `-${numbers.slice(0, 4)}` : '');
            input.value = formattedValue;
        } else {
            // First character is a number; format as 1234-AAA
            const [numbers, letters] = value.split(/([A-Za-z]+)/); // Split numbers and letters
            const formattedValue = numbers.slice(0, 4) + (letters ? `-${letters.slice(0, 3)}` : '');
            input.value = formattedValue;
        }
    };
    
    // Add a new car
    const addCar = () => {
        Swal.fire({
            title: 'Add New Car',
            html: `
                 <input id="newCarBrand" class="swal2-input blue-shade custom-placeholder" placeholder="Car Brand Name" required>
                <div id="logoDropdownContainer"></div> <!-- Logo dropdown container -->
                <input id="newLicensePlate" class="swal2-input blue-shade custom-placeholder" placeholder="License Plate (e.g., aaa-1234 or 1234-aaa)" maxlength="8" required oninput="handleLicensePlateInput(event)" />
                <input id="newSerialNumber" class="swal2-input blue-shade custom-placeholder" placeholder="Serial Number" required>
                <input id="newCustomerMobile" class="swal2-input blue-shade custom-placeholder" placeholder="Customer Mobile" required>
                <div id="branchSelectContainer"></div> <!-- This is where we will inject the select -->
            `,
            focusConfirm: false,
            confirmButtonText: 'Pick Up',
            cancelButtonText: 'Cancel',
            didOpen: () => {
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
                 // Render Logo Dropdown
                 const logoDropdownContainer = document.getElementById('logoDropdownContainer');
                 if (logoDropdownContainer) {
                     const logos = [
                         { id: 'alto', src: '/assets/images/cars/alto.png', alt: 'Alto' },
                         { id: 'sazuki', src: '/assets/images/cars/sazuki.png', alt: 'Sazuki' },
                         { id: 'ford', src: '/assets/images/cars/ford.png', alt: 'Ford' },
                     ];
 
                     const logoDropdown = (
                         <div style={{ marginTop: '10px', textAlign: 'center' }}>
                             <div
                                 className="custom-dropdown"
                                 style={{
                                     width: '58%',
                                     margin: '0 auto',
                                     zIndex: "1000",
                                     padding: '10px',
                                     border: '1px solid lightgray',
                                     borderRadius: '5px',
                                     backgroundColor: 'white',
                                     position: 'relative',
                                     cursor: 'pointer',
                                     textAlign: 'left',
                                 }}
                             >
                                 <span id="selectedLogoText" style={{ color: '#0A1E3F' }}>Choose Brand Logo</span>
                                 <div
                                     className="dropdown-options"
                                     style={{
                                         display: 'none',
                                         position: 'absolute',
                                         top: '100%',
                                         left: '0',
                                         right: '0',
                                         zIndex: 10,
                                         backgroundColor: 'white',
                                         border: '1px solid lightgray',
                                         borderRadius: '5px',
                                         maxHeight: '200px',
                                         overflowY: 'auto',
                                     }}
                                     id="logoOptions"
                                 >
                                     {logos.map((logo) => (
                                         <div
                                             key={logo.id}
                                             className="dropdown-option"
                                             style={{
                                                 display: 'flex',
                                                 alignItems: 'center',
                                                 padding: '5px',
                                                 cursor: 'pointer',
                                             }}
                                             onClick={() => {
                                                 // Update the selected logo
                                                 const selectedLogoText = document.getElementById('selectedLogoText');
                                                 if (selectedLogoText) {
                                                     selectedLogoText.innerHTML = `<img src="${logo.src}" alt="${logo.alt}" style="width:20px; height:20px; margin-right:5px;"> ${logo.alt}`;
                                                 }
                                                 const selectedLogoFile = document.getElementById('selectedLogoFile');
                                                 if (selectedLogoFile) {
                                                     selectedLogoFile.value = logo.src;
                                                 }
 
                                                 // Hide options
                                                 const logoOptions = document.getElementById('logoOptions');
                                                 if (logoOptions) logoOptions.style.display = 'none';
                                             }}
                                         >
                                             <img
                                                 src={logo.src}
                                                 alt={logo.alt}
                                                 style={{ width: '30px', height: '35px', marginRight: '10px' }}
                                             />
                                             {logo.alt}
                                         </div>
                                     ))}
                                 </div>
                                 <input type="hidden" id="selectedLogoFile" value="" />
                             </div>
                         </div>
                     );
                     ReactDOM.render(logoDropdown, logoDropdownContainer);
 
                     // Toggle dropdown visibility
                     const dropdown = document.querySelector('.custom-dropdown');
                     if (dropdown) {
                         dropdown.addEventListener('click', () => {
                             const logoOptions = document.getElementById('logoOptions');
                             if (logoOptions) {
                                 logoOptions.style.display = logoOptions.style.display === 'none' ? 'block' : 'none';
                             }
                         });
                     }
                 }
            },
            preConfirm: async () => {
                const carBrand = document.getElementById('newCarBrand').value;
                const licensePlate = document.getElementById('newLicensePlate').value?.split("-")?.join("");
                const customerMobile = document.getElementById('newCustomerMobile').value;
                const branch = user?.branch;
                const newCar = { carBrand, licensePlate, customerMobile, branch };

                 // Validate inputs
                 if (!carBrand) {
                    Swal.showValidationMessage('Car Brand is required');
                    return false;
                }
                if (!licensePlate) {
                    Swal.showValidationMessage('License Plate is required');
                    return false;
                }
                if (!customerMobile) {
                    Swal.showValidationMessage('Customer Mobile is required');
                    return false;
                }
                if (!/^\d{10}$/.test(customerMobile)) {
                    Swal.showValidationMessage('Customer Mobile must be a valid 10-digit number');
                    return false;
                }
                if (!branch) {
                    Swal.showValidationMessage('Please choose a branch');
                    return false;
                }

                
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/cars/add`, newCar);
                    fetchCars()
                    Swal.fire('Added!', 'New car has been added.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue adding the car.', 'error');
                }
            }
        });
    };

    // Actions for View, Edit, Delete, and Drop it
    const actions = [
        {
            label: "View",
            icon: <Eye size={16} />,
            onClick: (row) => showViewAlert(row),
        },
        {
            label: "Edit",
            icon: <Edit size={16} />,
            onClick: (row) => editCar(row),
        },
        // {
        //     label: "Delete",
        //     icon: <Trash2 size={16} />,
        //     onClick: (row) => deleteCar(row),
        // },
        {
            label: "Drop it",
            icon: <CheckCircle size={16} />,
            onClick: (row) => dropCar(row),
        },
    ];

    const tableData = data?.filter(x => x?.status === "picked")?.map(x=>{
        const logo = x?.carBrandLogo?.data;
        const contentType = x?.carBrandLogo?.contentType;  
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;   
        return {
            name : x?.name,
            branch : x?.branch?.name,
            branchId : x?.branch?._id,
            carBrandLogo : logoBase64,
            driver : x?.driver?.name,
            customerMobile : x?.customerMobile,
            carBrand : x?.carBrand,
            licensePlate : x?.licensePlate,
            pickupTime : new Date(x?.pickupTime).toLocaleDateString() + " "+  new Date(x?.pickupTime).toLocaleTimeString(),
            _id : x?._id,
            status : x?.status,
            dropOffTime : x?.dropOffTime ? new Date(x?.dropOffTime)?.toLocaleDateString()  + " "+  new Date(x?.dropOffTime).toLocaleTimeString() : "-----"
        }
    })
    return (
        <div>
            <Layout active='cars' sub_active='Picked' isAdmin={false}>
                <CardSection     title={t('picked_up_cars')}  // Translated title
                                    buttonText={t('+_add_car')}  // Translated button text
                                    onClickEvent={addCar}
>
                    <GenericTable data={tableData} columns={carColumns} action={true} actions={actions} />
                </CardSection>
            </Layout>
        </div>
    );
};

export default DriverPickedCars;
