import { useState } from "react";
import axios from "../../../config/server"; // Import Axios
import { Form, Button, Container } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";


const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("en")
  const changeLanguage = (lng) => {
    setSelectedLang(lng)
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-container">
      {
        selectedLang === "en" ?
        <button className="btn border-0 blue-shade" onClick={() => changeLanguage('ar')}>ARABIC</button>
        :
        <button className="btn border-0 blue-shade" onClick={() => changeLanguage('en')}>ENGLISH</button>
      }
    </div>
  );
};
const Forgot = () => {
  const [step, setStep] = useState(1); // Step tracking
  const [input, setInput] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      if (input.email !== "") {
        await axios.post("/admin/send-otp", { email: input.email });
        setStep(2); // Move to OTP screen
      } else {
        alert(t("please_provide_valid_email"));
      }
    } catch (error) {
      alert(error.response?.data?.message || t("error_sending_otp"));
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      if (input.otp !== "") {
        await axios.post("/admin/verify-otp", {
          email: input.email,
          otp: input.otp,
        });
        setStep(3); // Move to password reset screen
      } else {
        alert(t("please_provide_valid_otp"));
      }
    } catch (error) {
      alert(error.response?.data?.message || t("error_verifying_otp"));
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      if (input.newPassword === input.confirmPassword && input.newPassword !== "") {
        await axios.post("/admin/reset-password", {
          email: input.email,
          newPassword: input.newPassword,
        });
        alert(t("password_reset_successful"));
        setStep(1); // Reset to email step
        setInput({ email: "", otp: "", newPassword: "", confirmPassword: "" }); // Clear inputs
      } else {
        alert(t("passwords_do_not_match"));
      }
    } catch (error) {
      alert(error.response?.data?.message || t("error_resetting_password"));
    }
  };

  return (
    <Container
      fluid
      className="vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "#f8f9fa", position: "relative" }}
    >
          <LanguageSwitcher />
      <div
        className="forgot-container"
        style={{
          backgroundColor: "#f8f9fa",
          width: "90%",
          maxWidth: "400px",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {step === 1 && (
          <Form onSubmit={handleEmailSubmit}>
            <h2 className="mb-4 text-center" style={{ color: "#17294D" }}>
              {t("forgot_password")}
            </h2>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control
                type="email"
                className="custom-placeholder forgot_input"
                placeholder={t("enter_email")}
                value={input.email}
                onChange={(e) => setInput({ ...input, email: e.target.value })}
                required
              />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#17294D", borderRadius: "25px" }}
            >
              {t("send_otp")}
            </Button>
          </Form>
        )}

        {step === 2 && (
          <Form onSubmit={handleOtpSubmit}>
            <h2 className="mb-4 text-center" style={{ color: "#17294D" }}>
              {t("enter_otp")}
            </h2>
            <Form.Group controlId="formOtp" className="mb-3">
              <Form.Control
                type="text"
                className="custom-placeholder forgot_input"
                placeholder={t("enter_otp")}
                value={input.otp}
                onChange={(e) => setInput({ ...input, otp: e.target.value })}
                required
              />
            </Form.Group>
            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#17294D", borderRadius: "25px" }}
            >
              {t("verify_otp")}
            </Button>
          </Form>
        )}

        {step === 3 && (
          <Form onSubmit={handlePasswordSubmit}>
            <h2 className="mb-4 text-center" style={{ color: "#17294D" }}>
              {t("reset_password")}
            </h2>
            <Form.Group controlId="formNewPassword" className="mb-3 position-relative">
              <Form.Control
                className="custom-placeholder forgot_input"
                type={showPassword ? "text" : "password"}
                placeholder={t("new_password")}
                value={input.newPassword}
                onChange={(e) => setInput({ ...input, newPassword: e.target.value })}
                required
              />
              <div
                className="position-absolute"
                style={{
                  top: "50%",
                  right: "15px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="mb-3">
              <Form.Control
                className="custom-placeholder forgot_input"
                type={showPassword ? "text" : "password"}
                placeholder={t("confirm_password")}
                value={input.confirmPassword}
                onChange={(e) =>
                  setInput({ ...input, confirmPassword: e.target.value })
                }
                required
              />
            </Form.Group>

            <Button
              type="submit"
              className="w-100"
              style={{ backgroundColor: "#17294D", borderRadius: "25px" }}
            >
              {t("reset_password")}
            </Button>
          </Form>
        )}
      </div>
    </Container>
  );
};

export default Forgot;
