import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL
// Add a request interceptor to the global Axios instance
axios.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('token'); // Adjust the key as per your implementation
    axios.defaults.baseURL = process.env.REACT_APP_API_URL
    if (token) {
      // Attach the token to the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle errors during the request setup
    return Promise.reject(error);
  }
);

export default axios;