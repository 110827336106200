import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/login/Login";
import Forgot from "./pages/auth/login/Forgot-password";
import AuthProvider from "./auth/AuthProvider";
import { AdminRoute, DriverRoute } from "./auth/PrivateRoute";
import AdminDashboard from "./pages/admin/AdminDashboard";
import DriverDashboard from "./pages/driver/DriverDashboard";
import 'bootstrap/dist/css/bootstrap.min.css';
import Unauthorized from "./pages/Unauthorized";
import Drivers from "./pages/admin/Drivers";
import Users from "./pages/admin/Users";
import Branches from "./pages/admin/Branches";
import PickedCars from "./pages/admin/PickedCars";
import DropedCards from "./pages/admin/DropedCars";
import DriverPickedCars from "./pages/driver/PickedCars";
import DriverDropedCars from "./pages/driver/DriverDropedCars";
import Profile from "./pages/admin/Profile";
import DriverProfile from "./pages/driver/DriverProfile";
import 'animate.css';


// Import CSS
import './styles/custom.css';
import './App.css';
import RequestCarPage from "./pages/customer/RequestCar";
import FeedbackPage from "./pages/customer/Feedback";

function App() {
  return (
    <div className="App-header">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route
              path="/customer/:branchId/:serialNumber/:plateNumber"
              element={<RequestCarPage />}
            />
            <Route
              path="/customer/feedback/:branchId/:serialNumber/:plateNumber"
              element={<FeedbackPage />}
            />


            {/* Admin and Driver Profile */}

            {/* Admin only routes */}
            <Route element={<AdminRoute />}>
              <Route path="/" element={<AdminDashboard />} />
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/dashboard/cars/picked" element={<PickedCars />} />
              <Route path="/admin/dashboard/cars/droped" element={<DropedCards />} />
              <Route path="/admin/dashboard/branches" element={<Branches />} />
              <Route path="/admin/dashboard/drivers" element={<Drivers />} />
              <Route path="/admin/dashboard/user" element={<Users />} />
              <Route path="/admin/profile" element={<Profile />} />
              <Route path="*" element={<Unauthorized />} />
            </Route>

            {/* Driver only routes */}
            <Route element={<DriverRoute />}>
              <Route path="/driver" element={<DriverDashboard />} />
              <Route path="/driver/dashboard" element={<DriverDashboard />} />
              <Route path="/driver/dashboard/cars/droped" element={<DriverDropedCars />} />
              <Route path="/driver/dashboard/cars/picked" element={<DriverPickedCars />} />
              <Route path="/driver/profile" element={<DriverProfile />} />
            </Route>

            {/* Unauthorized page */}
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
