import { useParams } from "react-router-dom";
import axios from "../../config/server";
import React, { useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner'
import { dumyUser } from "../../constants";

const FeedbackPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [serviceRating, setServiceRating] = useState(0);
  const [driverRating, setDriverRating] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const { branchId, serialNumber, plateNumber } = useParams();
  const [feedback, setFeedback] = useState("");
  const [data, setData] = useState({})
  useEffect(() => {
    const validateCarDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/cars/validate", {
          params: { branchId, serialNumber, plateNumber },
        });
        if (response.status === 200) {
          setIsLoading(false);
          
          setData(response?.data?.data)
        }
      } catch (error) {
        alert(error.response?.data?.message || "Validation failed");
      } finally {
        setIsLoading(false);
      }
    };

    validateCarDetails();
  }, [branchId, serialNumber, plateNumber]);
  const handleRatingClick = (type, rating) => {
    if (type === "service") {
      setServiceRating(rating);
    } else if (type === "driver") {
      setDriverRating(rating);
    }
  };
 const handleNextStep = () => {
  if (driverRating === 0 || serviceRating === 0) {
    alert(`Please provide feedback ${ serviceRating === 0 ? "for Service" : "to Driver"}`)
    return
  }
  setCurrentStep(2)
 }
  const handleSubmit = async() => {
    const feedbackData = {
      serviceRating,
      driverRating,
      feedback,
      branchId, serialNumber, plateNumber
    };
    const response = await axios.post('/cars/submit-feedback', feedbackData);

    // Reset form or provide feedback to the user
    alert("Thank you for your feedback!");
    setCurrentStep(1);
    setServiceRating(0);
    setDriverRating(0);
    setFeedback("");
  };
  const parseBase64Image = (data, profile) => {
    const logo = data?.[(profile === "carBrandLogo" ? 'carBrandLogo' : 'profilePicture')]?.data;
    const contentType = data?.[(profile === "carBrandLogo" ? 'carBrandLogo' : 'profilePicture')]?.contentType;
    const logoBase64 = logo ? `data:${contentType};base64,${logo}` : profile === "profilePicture" ? dumyUser : "/assets/images/logo.png";
    return logoBase64
  }
  const branchLogo = parseBase64Image(data, "carBrandLogo")
  const driverLogo = parseBase64Image(data, "profilePicture")
  return (
    <div className="container">
      {currentStep === 1 && (
        <div className="rating-screen">
            <img src="/assets/images/logo.png" style={{height: "60px", width: "100px"}} alt="Hilton Logo" className="footer-logo d-flex mx-auto" />
            <img src={branchLogo} style={{width: "80px"}} alt="Hilton Logo" className="footer-logo d-flex mx-auto" />
          <main className="main-content">
            <h2 className="title blue-shade">IMPROVE US</h2>
            {
              isLoading ? 
          <Oval
            visible={isLoading}
            height="40"
            width="40"
            color="gray"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
              :
              <>
            <p className="description blue-shade">Rate our service</p>
              <div className="stars">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={`service-${star}`}
                  className={`star ${star <= serviceRating ? "filled" : ""}`}
                  onClick={() => handleRatingClick("service", star)}
                >
                  ★
                </span>
              ))}
            </div>

            <p className="description blue-shade">Rate our driver</p>
            <div className="stars">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={`driver-${star}`}
                  className={`star ${star <= driverRating ? "filled" : ""}`}
                  onClick={() => handleRatingClick("driver", star)}
                >
                  ★
                </span>
              ))}
            </div>
            <img src={driverLogo} style={{width: "80px"}} alt="Hilton Logo" className="footer-logo d-flex mx-auto mb-2" />
            <button className="button" onClick={() => handleNextStep()}>
              NEXT
            </button>
              </>
            }
          </main>
        </div>
      )}

      {currentStep === 2 && (
        <div className="feedback-screen">
           <img src="/assets/images/logo.png" style={{width: "240px"}} alt="Hilton Logo" className="footer-logo d-flex mx-auto" />
           <img src={branchLogo} style={{width: "70px"}} alt="Hilton Logo" className="footer-logo d-flex mx-auto" />
          <main className="main-content">
            <h2 className="title blue-shade">A LINE FOR US</h2>
            <p className="description blue-shade">Your feedback matters</p>
            <textarea
              className="feedback-textarea bg-white mb-5"
              placeholder="Write something..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
            <button className="button" onClick={handleSubmit}>
              SUBMIT
            </button>
          </main>
        </div>
      )}

      <style jsx>{`
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100vh;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .header {
          margin-top: 20px;
          text-align: center;
        }

        .brand {
          font-size: 24px;
          font-weight: bold;
        }

        .footer-logo {
          max-width: 80px;
          margin-top: 10px;
        }

        .main-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .title {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .description {
          font-size: 16px;
          margin: 10px 0;
        }

        .stars {
          display: flex;
          justify-content: center;
          margin: 10px 0;
        }

        .star {
          font-size: 24px;
          margin: 0 5px;
          cursor: pointer;
          color: #ccc;
          transition: color 0.2s;
        }

        .star.filled {
          color: gold;
        }

        .feedback-textarea {
          width: 100%;
          height: 150px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 15px;
          resize: none;
          margin-bottom: 20px;
        }

        .button {
          background-color: #001f3f;
          color: white;
          border: none;
          border-radius: 50px;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .button:hover {
          background-color: #0056b3;
        }
      `}</style>
    </div>
  );
};

export default FeedbackPage;