import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { Eye, Edit, Trash } from 'lucide-react';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';
import { Form } from 'react-bootstrap';
import axios from 'axios'; // Import axios
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const initialBranchData = [];

export const getbranchColumns = (t) => [
    { header: t("logo"), accessor: "logo", type: "image" },
        { header: t("name"), accessor: "name", type: "text" },
        { header: t("active"), accessor: "isActive", type: "checkbox" },
        { header: t("address"), accessor: "address", type: "text" },
];

const Branches = () => {
    const [branchData, setBranchData] = useState(initialBranchData);
    const { t } = useTranslation();
    const branchColumns = getbranchColumns(t);

    // Fetch branches on component mount
    const fetchBranches = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranchData(response.data); // Assuming the response returns an array of branches
        } catch (error) {
            Swal.fire('Error', t('failed_to_load_branches'), 'error');
        }
    };

    useEffect(() => {
        fetchBranches();
    }, []); // Empty dependency array ensures this runs once when the component mounts

    // Add Branch
    const addBranch = () => {
        Swal.fire({
            title: t('add_new_branch'),  // Translation key for "Add New Branch"
            html: ReactDOMServer.renderToStaticMarkup(
                <div>
                    <Form className="text-start">
                        <Form.Group className="mb-3">
                            <Form.Label className="blue-shade">{t('logo')}</Form.Label>  {/* Translation key for "Logo" */}
                            <Form.Control className="bg-white blue-shade" type="file" id="branchLogo" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="blue-shade">{t('name')}</Form.Label>  {/* Translation key for "Name" */}
                            <Form.Control className="bg-white blue-shade" type="text" id="newName" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="blue-shade">{t('address')}</Form.Label>  {/* Translation key for "Address" */}
                            <Form.Control className="bg-white blue-shade" type="text" id="newAddress" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label={t('active')}  
                                defaultChecked
                                id="newIsActive"
                            />
                        </Form.Group>
                    </Form>
                </div>
            ),
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            showCancelButton: true,
            confirmButtonText: t('add_branch'),  // Translation key for "Add Branch"
            cancelButtonText: t('cancel'),  // Translation key for "Cancel"
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            preConfirm: () => {
                const newBranch = {
                    logo: document.getElementById('branchLogo').files[0], // Get the file from the file input
                    name: document.getElementById('newName').value,
                    adress: document.getElementById('newAddress').value,
                    isActive: document.getElementById('newIsActive').checked,
                };

                const formData = new FormData();
                formData.append('logo', newBranch.logo);
                formData.append('name', newBranch.name);
                formData.append('description', newBranch.adress);
                formData.append('isActive', newBranch.isActive);

                // Send the form data to the backend API
                return axios.post(`${process.env.REACT_APP_API_URL}/branches`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    return response.data.branch; // Return the newly added branch
                }).catch(error => {
                    Swal.showValidationMessage(`${t('request_failed')}: ${error}`);  // Translation key for error message
                });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                fetchBranches()
                Swal.fire(t('added'), `${result.value.name} ${t('has_been_added')}`, 'success');  // Translated success message
            }
        });
    };

    // Edit Branch
    const showEditBranchAlert = (row) => {
        const htmlContent = ReactDOMServer.renderToStaticMarkup(
            <div>
                <Form className="text-start">
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">{t('name')}</Form.Label>
                        <Form.Control className="bg-white blue-shade" type="text" defaultValue={row.name} id="editName" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">{t('address')}</Form.Label>
                        <Form.Control className="bg-white blue-shade" type="text" defaultValue={row.address} id="editAddress" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" className="blue-shade" label={t('active')} defaultChecked={row.isActive} id="editIsActive" />
                    </Form.Group>
                </Form>
            </div>
        );

        Swal.fire({
            title: `${t('edit_branch')}: ${row.name}`,  // Translation key for "Edit Branch"
            html: htmlContent,
            confirmButtonText: t('save_changes'),  // Translation key for "Save Changes"
            cancelButtonText: t('cancel'),  // Translation key for "Cancel"
            showCancelButton: true,
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            preConfirm: () => {
                const updatedBranch = {
                    ...row,
                    name: document.getElementById('editName').value,
                    address: document.getElementById('editAddress').value,
                    isActive: document.getElementById('editIsActive').checked,
                };

                // Send the updated branch data to the backend API
                return axios.patch(`${process.env.REACT_APP_API_URL}/branches/${row.id}`, updatedBranch)
                    .then(response => response.data) // Return the updated branch data
                    .catch(error => {
                        Swal.showValidationMessage(`${t('request_failed')}: ${error}`);  // Translation key for error message
                    });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                fetchBranches()
                Swal.fire(t('updated'), `${result?.value?.branch?.name} ${t('has_been_updated')}`, 'success');  // Translated success message
            }
        });
    };

    // Delete Branch
    const showDeleteBranchAlert = (row) => {
        Swal.fire({
            title: `${t('are_you_sure')} ${row.name}?`,  // Translation key for "Are you sure"
            icon: 'warning',
            confirmButtonText: t('delete'),  // Translation key for "Delete"
            confirmButtonColor: '#d33',
            cancelButtonText: t('cancel'),  // Translation key for "Cancel"
            showCancelButton: true,
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call the delete API to remove the branch from the backend
                axios.delete(`${process.env.REACT_APP_API_URL}/branches/${row.id}`)
                    .then(() => {
                        setBranchData((prev) => prev.filter((branch) => branch?._id !== row.id));
                        Swal.fire(t('deleted'), `${row.name} ${t('has_been_deleted')}`, 'success');  // Translated success message
                        fetchBranches()
                    })
                    .catch((error) => {
                        Swal.fire(t('error'), `${t('failed_to_delete')} ${row.name}. ${error}`, 'error');  // Translated error message
                    });
            }
        });
    };

    // Actions
    const actions = [
        {
            label: t("view"),  // Translation key for "View"
            icon: <Eye size={16} />,
            onClick: (row) => {                
                Swal.fire({
                    title: row.name,
                    html: `<p class="blue-shade"><strong>${t('address')}:</strong> ${row.address}</p>
                           <p class="blue-shade"><strong>${t('active')}:</strong> ${row.isActive ? t('yes') : t('no')}</p>`,
                    icon: 'info',
                    confirmButtonText: t('close'),  // Translation key for "Close"
                    customClass: {
                        popup: 'responsive-alert',
                    },
                    didOpen: () => {
                        // Apply custom colors to the title and text
                        const title = document.querySelector('.swal2-title');
                        const confirmBTN = document.querySelector('.swal2-confirm');
                        if (confirmBTN) {
                            confirmBTN.style.background = '#0A1E3F'; // Title color
                            confirmBTN.style.color = '#fff'; // Title color
                        }
                        if (title) {
                            title.style.color = '#0A1E3F'; // Title color
                        }
                    },
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                });
            },
        },
        {
            label: t("edit"),  // Translation key for "Edit"
            icon: <Edit size={16} />,
            onClick: (row) => showEditBranchAlert(row),
        },
        {
            label: t("delete"),  // Translation key for "Delete"
            icon: <Trash size={16} />,
            onClick: (row) => showDeleteBranchAlert(row),
        },
    ];

    const data = branchData?.map(x => {
        const logo = x?.logo?.data;
        const contentType = x?.logo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        return {
            id: x?._id,
            name: x?.name,
            logo: logoBase64,
            address: x?.address,
            isActive: x?.isActive
        }
    });

    return (
        <div>
            <Layout active={'branch'} isAdmin={true}>
            <CardSection 
                        title={t('branches')} 
                        onClickEvent={addBranch} 
                        buttonText={t('+_add_branch')} 
                    >
                    <GenericTable
                        data={data}
                        columns={branchColumns}
                        recordsPerPage={10}
                        actions={actions}
                        action={true}
                    />
                </CardSection>
            </Layout>
        </div>
    );
};

export default Branches;
