import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CardSection from '../ui/CardSection';
import CustomPieChart from './CustomPieChart';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
    const { t } = useTranslation();

    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                <p style={{ color: '#0A1E3F', fontWeight: 'bold' }}>{t('month')}: {label}</p>
                <p style={{ color: '#0A1E3F' }}>
                    {t('pickups')}: {payload[0].value}
                </p>
            </div>
        );
    }

    return null;
};

const MainContent = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]); // Raw car data
    const [lineChartData, setLineChartData] = useState([]); // Data for the line chart
    const [pieChartData, setPieChartData] = useState([]); // Data for the pie chart

    // Fetch car data from the backend
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
        } catch (error) {
            console.error(t('errorFetchingCars'), error);
        }
    };

    // Generate data for the line chart (monthly pickups)
    const processLineChartData = (cars) => {
        const monthlyCounts = {};
        const allMonths = Array.from({ length: 12 }, (_, i) =>
            new Date(new Date().getFullYear(), i).toLocaleString('default', { month: 'short', year: 'numeric' })
        );

        cars.forEach((car) => {
            if (car.pickupTime) {
                const month = new Date(car.pickupTime).toLocaleString('default', { month: 'short', year: 'numeric' });
                monthlyCounts[month] = (monthlyCounts[month] || 0) + 1;
            }
        });

        const chartDataArray = allMonths.map((month) => ({
            name: month,
            value: monthlyCounts[month] || 0,
        }));

        setLineChartData(chartDataArray);
    };

    const processPieChartData = (cars) => {
        const now = moment(); // Current date and time
        const startOfWeek = now.clone().startOf('week'); // Start of the week (Sunday)
        const endOfWeek = now.clone().endOf('week'); // End of the week (Saturday)

        const filteredCars = cars.filter((car) => {
            const timeToCheck = car.status === 'droped' && car.dropOffTime
                ? moment(car.dropOffTime)
                : moment(car.pickupTime);

            return (
                timeToCheck.isBetween(startOfWeek, endOfWeek, 'day', '[]') &&
                timeToCheck.month() === now.month() &&
                timeToCheck.year() === now.year()
            );
        });

        const pickedCount = filteredCars.filter((car) => car.status === 'picked').length;
        const droppedCount = filteredCars.filter((car) => car.status === 'droped').length;

        setPieChartData([
            { name: t('pickedCars'), value: pickedCount },
            { name: t('droppedCars'), value: droppedCount },
        ]);
    };

    useEffect(() => {
        fetchCars();
    }, []);

    useEffect(() => {
        if (data.length) {
            processLineChartData(data);
            processPieChartData(data);
        }
    }, [data]);

    const now = moment();
    const startOfWeek = now.clone().startOf('week');
    const endOfWeek = now.clone().endOf('week');

    const formattedTitle = `${t('cars')} (${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')})`;

    return (
        <div>
            <Row>
                <Col lg={8} className="mb-4">
                    <CardSection title={t('monthlyPickupStatistics')} style={{ color: '#081e3c' }}>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={lineChartData}>
                                <CartesianGrid strokeDasharray="3 3" stroke="#0A1E3F" />
                                <XAxis dataKey="name" stroke="#0A1E3F" />
                                <YAxis stroke="#0A1E3F" />
                                <Tooltip content={<CustomTooltip />} />
                                <Line type="monotone" dataKey="value" stroke="#0A1E3F" strokeWidth={2} dot={{ fill: 'white' }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardSection>
                </Col>
                <Col lg={4} className="mb-4">
                    <CardSection title={formattedTitle}>
                        <CustomPieChart pieData={pieChartData} />
                    </CardSection>
                </Col>
            </Row>
        </div>
    );
};

export default MainContent;
