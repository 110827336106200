import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { Eye, Edit, Trash } from 'lucide-react';
import Swal from 'sweetalert2';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';

const Drivers = () => {
    const [drivers, setDrivers] = useState([]);
    const [branches, setBranches] = useState([]);
    const { t } = useTranslation();

    // Fetch drivers and branches from backend
    const fetchData = async () => {
        try {
            const driversResponse = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users/`);
            setDrivers(driversResponse.data?.filter(x => x?.role === "driver")); // Fetch drivers
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire(t('error'), t('failed_fetch_data'), 'error');
        }
    };
    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire(t('error'), t('failed_fetch_data'), 'error');
        }
    };
    useEffect(() => {
        fetchData();
        fetchBranches();
    }, []);

    // Define columns for the table
    const driverColumns = [
        { header: t('image'), accessor: "image", type: "image" },
        { header: t('full_name'), accessor: "name", type: "text" },
        { header: t('email'), accessor: "email", type: "text" },
        { header: t('active'), accessor: "isActive", type: "checkbox" },
        { header: t('branch'), accessor: "branch", type: "text" },
    ];

    // Add Driver
    const addDriver = () => {
        Swal.fire({
            title: t('add_new_driver'),
            html: ReactDOMServer.renderToStaticMarkup(
                <Form className='text-start'>
                    <Row>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="blue-shade">{t('full_name')}</Form.Label>
                                <Form.Control className='bg-white blue-shade' type="text" id="newFullName" />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="blue-shade">{t('email')}</Form.Label>
                                <Form.Control className='bg-white blue-shade' type="email" id="newEmail" />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="blue-shade">{t('branch')}</Form.Label>
                                <Form.Select id='newBranch' defaultValue="" style={{
                                    color : "#0A1E3F",
                                    background : "white",
                                    border: "1px solid lightgray"
                                }}>
                                    <option value="" disabled>{t('choose_branch')}</option>
                                    {branches.map((branch, index) => (
                                        <option value={branch?._id} key={index}>{branch.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="blue-shade">{t('profile_pic')}</Form.Label>
                                <Form.Control className='bg-white blue-shade' type="file" id="profileImage" accept="image/png, image/jpeg, image/jpg"/>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="blue-shade">{t('phone_number')}</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>05</InputGroup.Text>
                                    <Form.Control className="bg-white blue-shade custom-placeholder" 
                                        type="number" 
                                        id="mobile" 
                                        placeholder={t('mobile_number')} 
                                        aria-label="Phone number" 
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="blue-shade">{t('password')}</Form.Label>
                                <Form.Control className="bg-white blue-shade" type="text" id="password" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            label={t('active')}
                            defaultChecked
                            id="newIsActive"
                        />
                    </Form.Group>
                </Form>
            ),
            showCancelButton: true,
            confirmButtonText: t('add_driver'),
            cancelButtonText: t('cancel'),
            customClass: {
                popup: 'responsive-alert',
            },
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            preConfirm: async () => {
                const fullName = document.getElementById('newFullName').value;
                const email = document.getElementById('newEmail').value;
                const password = document.getElementById('password').value;
                const branch = document.getElementById('newBranch').value;
                const mobile = `05${document.getElementById('mobile').value}`;;
                const isActive = document.getElementById('newIsActive').checked;

                if (!fullName || !email || !branch || !mobile) {
                    Swal.showValidationMessage(t('all_fields_required'));
                    return false;
                }
                // Handle image upload
                const profile = document.getElementById('profileImage').files[0];
    
                // File size validation: max 1MB
                const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
                if (profile && profile.size > MAX_FILE_SIZE) {
                    Swal.showValidationMessage(t('file_too_large') + ` (Max: 1MB)`);
                    return false;
                }
                // Handle image upload if needed
                const image = profile || '/assets/images/default-profile.png';
                const formData = new FormData();
                formData.append('name', fullName);
                formData.append('email', email);
                formData.append('password', password);
                formData.append('mobile', mobile);
                formData.append('branch', branch);
                formData.append('role', "driver");
                formData.append('isActive', isActive);
                formData.append('profilePic', image);
                // Post the new driver to the backend API
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    return response.data; // Returning the added driver data
                } catch (error) {
                    Swal.showValidationMessage(`${t('error_adding_driver')}: ${error?.response?.data}`);
                    return false;
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                fetchData();
                fetchBranches();
                Swal.fire(t('added'), t('driver_added'), 'success');
            }
        });
    };


    // Edit Driver
    const editDriver = (row) => {
        Swal.fire({
            title: `${t('edit_driver')}: ${row.name}`,
            html: ReactDOMServer.renderToStaticMarkup(
                <Form className="text-start">
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">{t('full_name')}</Form.Label>
                        <Form.Control className='bg-white blue-shade' type="text" defaultValue={row.name} id="editFullName" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">{t('email')}</Form.Label>
                        <Form.Control className='bg-white blue-shade' type="email" defaultValue={row.email} id="editEmail" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">{t('branch')}</Form.Label>
                        <Form.Select id="editBranch" style={{
                                    color : "#0A1E3F",
                                    background : "white",
                                    border: "1px solid lightgray"
                                }}>
                            {branches?.map((branch, index) => (
                                <option value={branch?._id} selected={row?.branch_id?.toString() === branch?._id?.toString()} key={index}>{branch.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            className='blue-shade'
                            type="checkbox"
                            label={t('active')}
                            defaultChecked={row.isActive}
                            id="editIsActive"
                        />
                    </Form.Group>
                </Form>
            ),
            showCancelButton: true,
            confirmButtonText: t('save_changes'),
            cancelButtonText: t('cancel'),
            customClass: {
                popup: 'responsive-alert',
            },
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            preConfirm: async () => {
                const updatedDriver = {
                    ...row,
                    name: document.getElementById('editFullName').value,
                    email: document.getElementById('editEmail').value,
                    branch: document.getElementById('editBranch').value,
                    isActive: document.getElementById('editIsActive').checked,
                };

                try {
                    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/admin/user/${row.id}`, updatedDriver);
                    return response.data; // Return the updated driver
                } catch (error) {
                    Swal.showValidationMessage(`${t('error_updating_driver')}: ${error.message}`);
                    return false;
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                fetchData();
                Swal.fire(t('updated'), t('driver_updated'), 'success');
            }
        });
    };

    // Delete Driver
    const deleteDriver = (row) => {
        Swal.fire({
            title: `${t('are_you_sure')} ${row.name}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: t('delete'),
            confirmButtonColor: '#d33',
            cancelButtonText: t('cancel'),
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#d33'; // Delete color
                    confirmBTN.style.color = '#fff'; // Delete color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.REACT_APP_API_URL}/admin/user/${row.id}`)
                    .then(() => {
                        fetchData();
                        Swal.fire(t('deleted'), t('driver_deleted'), 'success');
                    }).catch((error) => {
                        Swal.fire(t('error'), `${t('driver_not_deleted')}: ${error.message}`, 'error');
                    });
            }
        });
    };

    // Actions for Table
    const actions = [
        {
            label: t("view"),  // Use translation key for "View"
            icon: <Eye size={16} />,
            onClick: (row) => Swal.fire({
                title: t('driver_details'),  // Use translation key for "Driver Details"
                text: `${row.name} (${row.email})`,
                didOpen: () => {
                    // Apply custom colors to the title and text
                    const title = document.querySelector('.swal2-title');
                    const confirmBTN = document.querySelector('.swal2-confirm');
                    const content = document.querySelector('.swal2-html-container');
            
                    if (title) {
                        title.style.color = '#0A1E3F'; // Title color
                    }
                    if (confirmBTN) {
                        confirmBTN.style.background = '#0A1E3F'; // Title color
                        confirmBTN.style.color = '#fff'; // Title color
                    }
                    if (content) {
                        content.style.color = '#0A1E3F'; // Text color
                    }
                }
            })            
        },
        {
            label: t("edit"),  // Use translation key for "Edit"
            icon: <Edit size={16} />,
            onClick: (row) => editDriver(row),
        },
        {
            label: t("delete"),  // Use translation key for "Delete"
            icon: <Trash size={16} />,
            onClick: (row) => deleteDriver(row),
        },
    ];
    
    const data = drivers?.map(x=>{
        const logo = x?.profilePicture?.data;
        const contentType = x?.profilePicture?.contentType;  
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;    
        return {
            id : x?._id,
            name : x?.name,
            email : x?.email,
            image : logoBase64,
            branch : x?.branch?.name,
            branch_id : x?.branch?._id,
            isActive : x?.isActive
        }
    })
    return (
        <Layout active={'driver'} isAdmin={true}>
            <CardSection title={t('drivers')} 
                    buttonText={t('+_add_driver')} 
                    onClickEvent={addDriver} >
                <GenericTable
                    columns={driverColumns}
                    data={data}
                    action={true}
                    actions={actions}
                    recordsPerPage={10}
                />
            </CardSection>
        </Layout>
    );
};

export default Drivers;
