import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import MainContent from '../../components/ghraphs/MainContent';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import axios from '../../config/server';
import Swal from 'sweetalert2';

  
const AdminDashboard = () => {
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const [branches, setBranches] = useState([]);

  const fetchData = async () => {
    try {
      const driversResponse = await axios.get(`${process.env.REACT_APP_API_URL}/drivers`);
      setDrivers(driversResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire(t('error'), t('failedToFetchData'), 'error');
    }
  };

  const fetchBranches = async () => {
    try {
      const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
      setBranches(branchesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire(t('error'), t('failedToFetchData'), 'error');
    }
  };

  useEffect(() => {
    fetchData();
    fetchBranches();
  }, []);

  const driverColumns = [
    { header: t("image"), accessor: "image", type: "image" },
    { header: t("fullName"), accessor: "name", type: "text" },
    { header: t("email"), accessor: "email", type: "text" },
    { header: t("active"), accessor: "isActive", type: "checkbox" },
    { header: t("branch"), accessor: "branch", type: "text" },
  ];

  const driversData = drivers?.map(x => {
    const logo = x?.profilePic?.data;
    const contentType = x?.profilePic?.contentType;
    const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
    return {
      id: x?._id,
      name: x?.name,
      email: x?.email,
      image: logoBase64,
      branch: x?.branch?.name,
      branch_id: x?.branch?._id,
      isActive: x?.isActive,
    };
  });

  return (
    <div>
      <Layout active={'dashboard'} isAdmin={true}>
        <div className="mb-4">
          <MainContent />
        </div>
        <div className="mb-4">
          <CardSection title={t("drivers")}>
            <GenericTable columns={driverColumns} data={driversData} recordsPerPage={5} />
          </CardSection>
        </div>
      </Layout>
    </div>
  );
};

export default AdminDashboard;
