import { useState } from "react";
import { useAuth } from "../../../auth/AuthProvider";
import { Form, Button, Container } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next"; 

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("en")
  const changeLanguage = (lng) => {
    setSelectedLang(lng)
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-container">
      {
        selectedLang === "en" ?
        <button className="btn border-0 blue-shade" onClick={() => changeLanguage('ar')}>ARABIC</button>
        :
        <button className="btn border-0 blue-shade" onClick={() => changeLanguage('en')}>ENGLISH</button>
      }
    </div>
  );
};
const Login = () => {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation(); // Use translation hook

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (input.username !== "" && input.password !== "") {
      const data = {
        email: input.username,
        password: input.password,
      };
      auth.loginAction(data);
      return;
    }
    alert(t('please_provide_valid_input')); // Translated error message
  };

  return (
    <>
    <Container
      fluid
      className="vh-100 d-flex align-items-start justify-content-center flex-column"
      style={{ backgroundColor: "#f8f9fa", position: "relative" }}
    >
    <LanguageSwitcher />
      <div
        className="login-container"
        style={{
          backgroundColor: "#f8f9fa",
          width: "90%",
          maxWidth: "1200px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "70px 20px",
        }}
      >
        <img src="/assets/images/logo.png" alt="Rzana" width={"250px"} height={"140px"} />
        <Form
          onSubmit={handleSubmitEvent}
          style={{ width: "100%", maxWidth: "300px" }}
        >
          <h2
            className="mb-4 text-center d-none d-sm-block"
            style={{ color: "#17294D", fontWeight: "700", fontSize: "20px" }}
          >
            {t('login_here')} {/* Translated title */}
          </h2>
          <Form.Group controlId="formUsername" className="mb-3">
            <Form.Control
              type="text"
              placeholder={t('enter_username')} 
              className="custom-placeholder"
              value={input.username}
              onChange={(e) =>
                setInput({ ...input, username: e.target.value })
              }
              style={{
                borderRadius: "25px",
                borderColor: "#17294D",
                color:"#0A1E3F",
                padding: "10px",
                backgroundColor: "#fff",
              }}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mb-3 position-relative">
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder={t('enter_password')}  
              className="custom-placeholder"
              value={input.password}
              onChange={(e) =>
                setInput({ ...input, password: e.target.value })
              }
              style={{
                borderRadius: "25px",
                borderColor: "#17294D",
                color:"#0A1E3F",
                padding: "10px",
                backgroundColor: "#fff",
              }}
              required
            />
            <div
              className="position-absolute"
              style={{
                top: "50%",
                right: "15px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FaEyeSlash size={20} color="#17294D" />
              ) : (
                <FaEye size={20} color="#17294D" />
              )}
            </div>
          </Form.Group>

          <div className="d-flex justify-content-between mb-4">
            <Form.Check
              type="checkbox"
              label={t('remember_me')}  
              defaultChecked
              style={{ color: "#17294D", borderRadius: "50%" }}
            />
            <a href="/forgot-password" style={{ fontSize: "14px", color: "#17294D" }}>
              {t('forgot_password')}  {/* Translated link */}
            </a>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <Button
              type="submit"
              style={{
                width: "50%",
                backgroundColor: "#17294D",
                borderColor: "#17294D",
                borderRadius: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t('login')}  {/* Translated button */}
            </Button>
          </div>
        </Form>
      </div>
    </Container>
    </>
  );
};

export default Login;
