import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const AuthContext = createContext();

const apiUrl = process.env.REACT_APP_API_URL;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [token, setToken] = useState(Cookies.get("site") || "");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Update localStorage when user or token changes
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }

    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [user, token]);

  const loginAction = async (data) => {
    try {
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const res = await response.json();

      if (res) {
        setUser({ ...res.user, role: res.user.role });
        setToken(res.token);
        Cookies.set("site", res.token, { expires: 20 / 1440 });
        if (res.user.role === 'admin') {
          navigate("/admin/dashboard");
        } else if (res.user.role === 'driver') {
          navigate("/driver/dashboard");
        }

        return;
      }

      throw new Error(res.message || "Login failed. Please check your credentials.");
    } catch (err) {
      console.error("Login Error:", err);
      alert("Login failed. Please check your credentials.");
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    Cookies.remove("site");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/auth/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
